import React from 'react'
import './info.css'

class Card extends React.Component{
    render() {
        return (
            <div id="partner-card">
                <img src={this.props.icon} onClick={() => window.open = this.props.url} alt="icon"/>
                <span id="info">
                    {this.props.name}<br />
                    <span className="about">
                        {this.props.info}
                    </span>
                </span>
            </div>
        )
    }
}

export default class PartnerInfo extends React.Component {
    render() {
        return (
            <div id="info-window">
                <div id="head">
                    <p id="title">
                        <u>Partnerships</u>
                    </p>
                </div>

                <div id="content">
                    <div id="about-partner">
                        The goal of our partnerships is to create a win-win situation that both sides profit. Below you can find all of our epic and awsome partners. We personally recommend to check them out.<br />
                        If you are interested to become a partner as well, feel free to join our <a href="https://join.discord-botlist.eu">Discord server</a> and open a partnership-ticket
                    </div>
                    <br />
                    <br />
                    <div id="list">
                        <Card icon="https://cdn.discord-botlist.eu/pictures/zap.png" name="Zap-Hosting" info="Zap Hosting is sponsoring this project with on of their V-Servers. Thank you <3" url="https://zap-hosting.com/en" />
                        <Card icon="https://media.discordapp.net/attachments/868023324402397235/869286242754383902/logo.png?width=905&height=905" name="Caards" info="Caards.me is a website where you can create your caard! You can link your social network accounts, find friends, and much more!" url="https://www.caards.me" />
                    </div>

                </div>

            </div>
        )
    }
}