import fetch from 'cross-fetch'
import React from 'react'
import config from '../../../assets/config.json'

class Edituser extends React.Component{
  constructor(){
    super()
    this.submitProfileEdit = this.submitProfileEdit.bind(this)
    this.state = { }
  }

  async submitProfileEdit() {
    fetch(`${config.hosts.INTERNAL_API}/useredit`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        bio: document.getElementById("bio").value,
        discord: document.getElementById("discord").value,
        website: document.getElementById("website").value
      }),
      'withCredentials': true,
      credentials: 'include'
    }).then(() => {
      window.location.reload()
    })
  }

  async deleteProfile() {
    fetch(`${config.hosts.INTERNAL_API}/useredit`, {
      method: "DELETE",
      'withCredentials': true,
      credentials: 'include'
    }).then(() => {
      window.location.reload()
    })
  }

  async componentDidMount() {
    const data = await fetch(`${config.hosts.INTERNAL_API}/manageProfile`, {
      credentials: 'include'
    }).then(res => res.json()).catch(() => undefined)
    if (!data) return window.location.href = config.hosts.LOGIN
    this.setState(data)
  }
  
  render(){
    return(
      <>
        <strong style={{fontSize: "30px"}}>Welcome {getCookie("uid")}</strong><br />
        <div id="error"></div>
        <div id="input-desc" style={{marginTop: "10px", marginBottom: "3px"}}>Your Bio<dfn class="tooltip">?<span role="tooltip"><strong>Bio</strong><br />This will appear on your profile page</span></dfn></div>
        <input id="bio" class="input" style={{width: "30%", color: "white", padding: "20px 10px"}} value={this.state.bio} onChange={e => this.setState({bio: e.target.value})} /> 
        <div id="input-desc" style={{marginTop: "10px", marginBottom: "3px"}}>Discord<dfn class="tooltip">?<span role="tooltip"><strong>Discord</strong><br />You can provide a link to your discord server here. This will show up on your profile page</span></dfn></div>
        <input id="discord" class="input" style={{width: "30%", padding: "20px 10px"}} value={this.state.discord} onChange={e => this.setState({discord: e.target.value})} />
        <div id="input-desc" style={{marginTop: "10px", marginBottom: "3px"}}>Website<dfn class="tooltip">?<span role="tooltip"><strong>Website</strong><br />You can provide a link to your Website here. This will show up on your profile page</span></dfn></div>
        <input id="website" class="input" style={{width: "30%", padding: "20px 10px"}} value={this.state.website} onChange={e => this.setState({website: e.target.value})} />
        <br />
        <div id="input-desc" style={{marginTop: "10px", marginBottom: "3px"}}>Invite code<dfn class="tooltip">?<span role="tooltip"><strong>Invite code</strong><br />Invite new users and tell them to provide this code to get a gift</span></dfn></div>
        <input id="website" class="input" style={{width: "30%", padding: "20px 10px", cursor: "text"}} value={this.state.inviteCode} disabled />
        <br />
        <div id="btns">
          <div id="button-prof" onClick={() => this.submitProfileEdit()}>Submit</div>
          <div id="button-prof-del" onClick={() => this.deleteProfile()}>Delete</div>
        </div>
      </>
    )
  }
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default Edituser