import React from 'react'
import './gifts.css'
import fetch from 'cross-fetch'
import config from '../../assets/config.json'
import { ReactComponent as Loading } from '../../assets/loading.svg';

class Gift extends React.Component {
    constructor() {
        super()
        this.state = {
            bots: null,
            gift: {},
            isInvalid: false
        }
    }

    async componentDidMount() {
        const gift = await fetch(`${config.hosts.INTERNAL_API}/gift/${this.props.match.params.gift}`, {
            credentials: "include"
        }).then(res => res.json()).catch(() => {
            this.setState({isInvalid: true})
        })
        this.setState({gift: gift || {}})
        const bots = await fetch(`${config.hosts.INTERNAL_API}/bots?gift=${gift.type}`, {
            credentials: "include"
        }).then(res => res.json())
        this.setState({bots: bots})
    }

    async submit() {
        var e = document.getElementById("SELECT_BOT")
        var botid = e.options[e.selectedIndex].value
        await fetch(`${config.hosts.INTERNAL_API}/gift/${this.props.match.params.gift}/${botid}`, {
            method: "POST",
            credentials: 'include'
        }).then(res => res.json())
        window.location.href = `/edit/${botid}`
    }

    render() {
        if (!getCookie("uid_id")) return <>{window.location.href = config.hosts.LOGIN}</>

        if (this.state.isInvalid) return (
            <>
                <center>
                    <div id="popup">
                        <h3>Oh no...</h3>
                        <p style={{color: "gray"}}>
                            It looks like that this gift is invalid 😔
                        </p>
                    </div>
                </center>
            </>
        )

        if (!this.state.gift.name) return <Loading />

        return (
            <>
               <center>
                    <div id="popup">
                        <h3>A wild gift appeared</h3>
                        <p style={{color: "gray", marginTop: "-10px"}}>
                            Hey {getCookie("uid")}, you got gifted a <b>{this.state.gift.name}</b> for <b>{this.state.gift.duration}</b>
                        </p>
                        <br />
                        {this.state.bots?.length || 0 >= 1 ? 
                        <>
                            <p style={{color: "gray"}}>
                                Please select which bot should be effected
                            </p>
                            <select id="SELECT_BOT">
                                {this.state.bots?.map(bot => <option value={bot.id}>{bot.name}</option>)}
                            </select> <br />
                            <button onClick={() => this.submit()}>
                                Select
                            </button>
                        </> : <>
                            <i style={{color: "gray"}}>There is currently no bot for which you can redeem your gift</i>
                        </>}
                    </div>
               </center>
            </>
        )
    }
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export default Gift