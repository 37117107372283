import React from 'react'
import config from '../../assets/config.json'
import Error404 from '../404/404'
import fetch from 'cross-fetch'
import { ReactComponent as Loading } from '../../assets/loading.svg';
import './report.css'

class Report extends React.Component {
    constructor() {
        super()
        this.state = {
            botid: null,
            botname: null,
            isSending: false,
            error: null,
            error404: false,
            success: false
        }
    }

    async componentDidMount() {
        const data = await fetch(`${config.hosts.INTERNAL_API}/botdata?id=${this.props.match.params.botid}`, {
            credentials: 'include'
        }).then(res => res.json()).catch(() => undefined)
        if (!data) return this.setState({error404: true})
        if (!data.isLoggedIn) return window.location.href = config.hosts.LOGIN
        this.setState({
            botname: data.botname,
            botid: data.botid,
            icon: data.avatarBild
        })
    }

    async report() {
        this.setState({
            isSending: true,
            error: null
        })

        const description = document.getElementById("description").value
        if (description.length < 30) return this.setState({
            error: `Please provide at least 30 character (${30 - description.length} left)`
        })

        const data = await fetch(`${config.hosts.INTERNAL_API}/api/reports/${this.state.botid}`, {
            method: "POST",
            headers: {
                description: description
            },
            credentials: 'include'
        }).then(res => res.json()).catch(() => undefined)
        if (!data) window.location.href = config.hosts.LOGIN
        if (data.error) return this.setState({
            error: data.error
        })
        this.setState({
            success: true
        })
    }

    render() {
        if (this.state.success) return (
            <div id="report_center">
                <div id="report-p">
                    <div id="head">
                        <img alt="" src={this.state.icon} /> <b>{this.state.botname}</b>
                    </div>
                    <br />
                    <br />
                    <b style={{color: "gray"}}>Thank you!</b>
                    <p style={{color: "gray"}}>We received your report and we'll investigate it as soon as possible</p>
                </div>
            </div>  
        )

        if (this.state.error404) return <Error404 />

        if (!this.state.botid) return (
            <div id="CALLBACK_LOADING">
                <Loading />
            </div>
        )

        return (
            <div id="report_center">
                <div id="report-p">
                    <div id="head">
                        <img alt="" src={this.state.icon} /> <b>{this.state.botname}</b>
                    </div>

                    <textarea id="description" placeholder="Please describe your problem as detailed as possible"></textarea>

                    <p id="error-report">
                        {this.state.error}
                    </p>
                    
                    <button onClick={() => this.report()}>
                        {this.state.isSending && !this.state.error ? <span id="loading-btn"><Loading /></span> : "Report"}
                    </button>
                </div>
            </div>
        )
    }
}

export default Report