import React from 'react'
import './terms.css'

export default class Terms extends React.Component {
    render() {
        return (
            <div id="terms-window">
                <div id="head">
                    <p id="title">
                        <u>Terms of Service</u>
                    </p>
                    <p id="sub-title">
                        <span id="last-modified">
                            Last modified on 02/02/2022 (MM/DD/YYYY)
                        </span>
                    </p>
                </div>

                <div id="content">

                    <section id="introduction">
                        <p>
                            Introduction
                        </p>
                        This page contains the Terms of Service ("Terms of Service", "Agreement", "Terms of Use") that all users and Discord API Clients ("bots") must follow. discord-botlist.eu ("we", "dbleu", "the service", "us", "our", "here") is obliged to explain to you exact limits and rights. The Terms of Use are based not only on the will of the Administration Team but also on legal(-obligations).<br />
                        Due to the fact that our servers are located in Germany, the German constitutional law applies here as well.<br />
                        By using our service (1) you automatically enter into a valid contract between you (the "Consumer", "User"), us and the Terms of Use, (2) you accept our Terms of Use and (3) you confirm that you have reached the digital age of majority in your country or have the permission of your legal guardian.<br />
                        We reserve the right to make changes to our Terms of Use at any time without notice.<br />
                        If you do not agree to our Terms of Use, you agree not to use the Service.<br />
                        <br />
                        If you use a translator and discrepancies occur, the english and original version is valid.
                    </section>

                    <section id="intellectual_property">
                        <p>
                            Intellectual property
                        </p>
                        Virtual items (including but not limited to images, graphics, designs, text, videos, music, sounds) that belong to us and/or represent, reflect or identify us may not be reproduced as your own. You agree not to edit, copy, distribute, develop, post, publish, sell, distribute as your own, or otherwise reuse them without express written permission from an authorized person who owns the proprietary rights.
                    </section>

                    <section id="your_content">
                        <p>
                            Your content
                        </p>
                        All of your published content on this platform is available to the public. It is your responsibility to ensure that no illegal content (or content that otherwise violates our Terms of Use) is posted on your behalf.<br />
                        We do not accept any liability for what happens to your works. <br />
                        We do not tolerate any information that is not true and we ask you to remain truthful at all times.
                    </section>

                    <section id="your_content">
                        <p>
                            Listed bots
                        </p>
                        Our goal is to provide helpful, useful and/or entertaining bots to everyone and to give every developer the opportunity to advertise the bot without any costs. In order to make this possible, we have certain requirements and reasons to decline/rescind the publication of a bot:<br/>
                        The bot...
                        <ul>
                            <li>
                                needs to provide a list with all existing and accessible commands for the user 
                            </li>
                            <li>
                                is not allowed to violate our Terms of Service, Discords <a href="https://discord.com/terms">Terms of Service</a> and <a href="https://discord.com/developers/docs/legal">Developer ToS</a>
                            </li>
                            <li>
                                needs to be made by the user who applies for it
                            </li>
                            <li>
                            is not allowed to be a copy of an already existing bot (unmodified instances)
                            </li>
                            <li>
                                must not send content to a location for which it is not officially intended
                            </li>
                            <li>
                                should not be created with a tool (e.g. botghost, BDFD, DBD) without leaving a comment in the long and/or short description when submitting it, and it must have at least 5 custom commands
                            </li>
                            <li>
                                is forbidden to allow users to perform actions on Discord without having the required permissions
                            </li>
                        </ul>
                        <p>
                            Votes
                        </p>
                        Our main goal is to provide the best user experience possible. That's why we do allow you to vote with an alt for you bot. However, if we notice an excessive abuse of this feature, we reserve the right to remove all votes of the bot to keep everything fair for everybody.
                    </section>

                    <section id="privacy">
                        <p>
                            Privacy
                        </p>
                        To learn more about our privacy policy, please see <a href="/privacy">here</a>.
                    </section>

                    <section id="violations">
                        <p>
                            Violations
                        </p>
                        In case of violations of our terms of service, we reserve the right to delete the respective (and related) content, to ban the responsible user and to take legal action.
                    </section>

                </div>
            </div>
        )
    }
}