import React from 'react'
import config from '../../assets/config.json'
import Error404 from '../404/404'
import fetch from 'cross-fetch'
import { ReactComponent as Loading } from '../../assets/loading.svg';
import './cert.css'

class Cert extends React.Component {
    constructor() {
        super()
        this.state = {
            botid: null,
            botname: null,
            isSending: false,
            error: null,
            error404: false,
            server_count: null,
            upvotes: null,
            success: false
        }
    }

    async componentDidMount() {
        const data = await fetch(`${config.hosts.INTERNAL_API}/botdata?id=${this.props.match.params.botid}`, {
            credentials: 'include'
        }).then(res => res.json()).catch(() => undefined)
        if (!data || data.warn) return this.setState({error404: true})
        if (!data.isLoggedIn) return window.location.href = config.hosts.LOGIN
        this.setState({
            botname: data.botname,
            botid: data.botid,
            icon: data.avatarBild,
            server_count: data.server_count,
            upvotes: data.upvotes.length
        })
    }

    async cert() {
        this.setState({
            isSending: true,
            error: null
        })

        const description = document.getElementById("description").value
        if (description.length < 80) return this.setState({
            error: `Please provide at least 80 character (${80 - description.length} left)`
        })

        const data = await fetch(`${config.hosts.INTERNAL_API}/api/cert/${this.state.botid}`, {
            method: "POST",
            headers: {
                description: description
            },
            credentials: 'include'
        }).then(res => res.json()).catch(() => undefined)
        if (!data) return this.setState({
            error: "An unknown error occured"
        })
        if (data.error) return this.setState({
            error: data.error
        })
        this.setState({
            success: true
        })
    }

    render() {
        if (this.state.success) return (
            <div id="cert_center">
                <div id="cert">
                    <div id="head">
                        <img alt="" src={this.state.icon} /> <b>{this.state.botname}</b>
                    </div>
                    <br />
                    <br />
                    <b style={{color: "gray"}}>Thank you!</b>
                    <p style={{color: "gray"}}>We received your request and we'll take a closer look as soon as possible. Make sure to keep your dms open for our admins</p>
                </div>
            </div>  
        )

        if (this.state.error404) return <Error404 />

        if (!this.state.botid) return (
            <div id="CALLBACK_LOADING">
                <Loading />
            </div>
        )

        return (
            <div id="cert_center">
                <div id="cert">
                    <div id="head">
                        <img alt="" src={this.state.icon} />
                        <p>
                            <b>{this.state.botname}</b> <br/>
                            <span style={{color: "gray"}}>
                                <b>{this.state.server_count}</b> server | <b>{this.state.upvotes}</b> upvotes
                            </span>
                        </p>
                    </div>

                    <textarea id="description" placeholder="Tell us more about your Bot and why we should certify it"></textarea>

                    <p id="error-cert">
                        {this.state.error}
                    </p>
                    
                    <button onClick={() => this.cert()}>
                        {this.state.isSending && !this.state.error ? <span id="loading-btn"><Loading /></span> : <span id="cert-request">Request <image alt="medal" src="https://cdn.discord-botlist.eu/pictures/certified_bot.svg" /></span>}
                    </button>
                </div>
            </div>
        )
    }
}

export default Cert