import React from 'react'
import './staffs.css'
import fetch from 'cross-fetch'
import config from '../../assets/config.json'

class Card extends React.Component {
    render() {
        return (
            <div className="card">
                <img src={this.props.icon} alt="" />
                <div className="content">
                    <p className="name">
                        {this.props.name}
                    </p>
                    <p className="department">
                        {this.props.department}
                    </p>
                </div>
            </div>
        )
    }
}

class Staffs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            staffs: []
        }
    }

    async componentDidMount() {
        const data = await fetch(`${config.hosts.INTERNAL_API}/staffs`).then(res => res.json())
        this.setState({
            staffs: data
        })
    }

    render() {
        return (
            <div id="staffs">
                <h2>Administartion</h2>
                <div id="card-group">
                    {this.state.staffs.filter(e => e.department === "Administrator").map(staff => <Card name={staff.username} icon={staff.displayAvatarURL} department={staff.department} />)}
                </div>
                <h2>Management</h2>
                <div id="card-group">
                    {this.state.staffs.filter(e => e.department === "Management").map(staff => <Card name={staff.username} icon={staff.displayAvatarURL} department={staff.department} />)}
                </div>
                <h2>Developing</h2>
                <div id="card-group">
                    {this.state.staffs.filter(e => e.department === "Developer").map(staff => <Card name={staff.username} icon={staff.displayAvatarURL} department={staff.department} />)}
                </div>
                <h2>Moderation</h2>
                <div id="card-group">
                    {this.state.staffs.filter(e => e.department === "Moderator").map(staff => <Card name={staff.username} icon={staff.displayAvatarURL} department={staff.department} />)}
                </div>
                <h2>Support</h2>
                <div id="card-group">
                    {this.state.staffs.filter(e => e.department === "Supporter").map(staff => <Card name={staff.username} icon={staff.displayAvatarURL} department={staff.department} />)}
                </div>
            </div>
        )
    }
}

export default Staffs