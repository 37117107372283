import React from 'react'

class TicketRedirect extends React.Component{
    constructor(params){
        super(params)
    }

    componentDidMount() {
        window.location.href = "./new"
    }

    render(){
        return(
            <>
            </>
        )
    }
}

export default TicketRedirect;