import React from 'react'
import './info.css'

export default class CertInfo extends React.Component {
    render() {
        return (
            <div id="info-window">
                <div id="head">
                    <p id="title">
                       <u>Imprint for discord-botlist.eu</u>
                    </p>
                </div>

                <div id="content" style={{color: "grey"}}>
                Bendix Siegert<br/>

                Bergstraße 40<br/>

                51503 Rösrath<br/>

                Germany<br /><br />

                <strong>Contact</strong><br />
                <strong>E-mail address:</strong> bendix@discord-botlist.eu<br /><br />

                <strong>Telephone:</strong> 01517 2655720<br /><br />

                <strong>Liability and property rights</strong><br />
                <strong>Disclaimer:</strong> The contents of this online offer have been prepared carefully and according to our current state of knowledge. However, they are for information purposes only and do not have any legally binding effect, unless it concerns legally obligatory information (e.g. the imprint, the data protection declaration, general terms and conditions or obligatory instructions for consumers). We reserve the right to change or delete the contents in whole or in part, provided that contractual obligations remain unaffected. All offers are subject to change and non-binding.<br /><br />

                <strong>Links to external websites:</strong> <br />The contents of external websites to which we refer directly or indirectly are outside our area of responsibility and we do not adopt them as our own. We accept no responsibility for any content or disadvantages arising from the use of the information available on the linked websites.<br /><br />

                <strong>Copyrights and trademark rights:</strong><br /> All contents presented on this website, such as texts, photographs, graphics, brands and trademarks are protected by the respective protective rights (copyrights, trademark rights). The use, reproduction, etc. are subject to our rights or the rights of the respective authors or rights holders.<br /><br />

                <strong>Notification of legal violations:</strong><br />If you notice any legal violations within our Internet presence, please inform us of them. We will remove illegal content and links immediately after becoming aware of them.
                </div>

            </div>
        )
    }
}