import React from 'react'
import config from '../../assets/config.json'
import Error404 from '../404/404'
import fetch from 'cross-fetch'
import { ReactComponent as Loading } from '../../assets/loading.svg';
import './delete.css'

class Delete extends React.Component {
    constructor() {
        super()
        this.state = {
            botid: null,
            botname: null,
            isSending: false,
            error: null,
            error404: false,
            success: false
        }
    }

    async componentDidMount() {

        const data = await fetch(`${config.hosts.INTERNAL_API}/botdata?id=${this.props.match.params.botid}`, {
            credentials: 'include'
        }).then(res => res.json()).catch(() => undefined)
        if (!data || data.warn) return this.setState({error404: true})
        if (!data.isLoggedIn) return window.location.href = config.hosts.LOGIN
        this.setState({
            botname: data.botname,
            botid: data.botid,
            icon: data.avatarBild
        })
        document.getElementById("final-del-btn").disabled = true
    }

    async delete() {
        this.setState({
            isSending: true,
            error: null
        })

        const description = document.getElementById("description").value
        // if (description.length < 30) return this.setState({
        //     error: `Please provide at least 30 character (${30 - description.length} left)`
        // })

        const data = await fetch(`${config.hosts.INTERNAL_API}/api/delete/${this.state.botid}`, {
            method: "POST",
            headers: {
                description: description
            },
            credentials: 'include'
        }).then(res => res.json()).catch(() => undefined)
        if (!data) return this.setState({
            error: "An unknown error occured"
        })
        if (data.error) return this.setState({
            error: data.error
        })
        this.setState({
            success: true
        })
    }

    render() {
        if (this.state.success) return (
            <div id="delete_center">
                <div id="delete-p">
                    <div id="head">
                        <img alt="" src={this.state.icon} /> <b>{this.state.botname}</b>
                    </div>
                    <br />
                    <br />
                    <b style={{color: "gray"}}>Bye!</b>
                    <p style={{color: "gray"}}>We've deleted your bot</p>
                </div>
            </div>  
        )

        if (this.state.error404) return <Error404 />

        if (!this.state.botid) return (
            <div id="CALLBACK_LOADING">
                <Loading />
            </div>
        )

        return (
            <div id="delete_center">
                <div id="delete-p">
                    <div id="head">
                        <img alt="" src={this.state.icon} /> 
                        <span style={{color: "#ED4245"}}>
                            Delete <b>{this.state.botname}</b> permanently
                        </span>
                    </div>

                    <textarea id="description" placeholder="Why do you want to delete this bot?"></textarea>

                    <p id="error-delete">
                        {this.state.error}
                    </p>
                    
                    <input type="checkbox" onChange={(e) => {
                        if (e.target.checked) {
                            document.getElementById("final-del-btn").disabled = false
                        } else {
                            document.getElementById("final-del-btn").disabled = true
                        }
                    }} /> <span style={{color: "gray", fontSize: "14px"}}>This action cannot be undone</span> <br/>
                    <button id="final-del-btn" onClick={() => this.delete()}>
                        {this.state.isSending && !this.state.error ? <span id="loading-btn"><Loading /></span> : "Delete"}
                    </button>
                </div>
            </div>
        )
    }
}

export default Delete