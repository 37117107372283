import React from 'react'
import './vote.css'
import fetch from 'cross-fetch'
import { ReactComponent as Loading } from '../../assets/loading.svg'
import config from '../../assets/config.json'

const badgeURLS = {
    "CERTIFIED": "https://cdn.discord-botlist.eu/pictures/certified_bot.svg"
}
 
class Badge {
    size
    name
 
    constructor(name, size) {
        this.name = name
        this.size = size
    }
 
    getURL() {
        return this.url
    }
    getHTML() {
        return (
            <svg>
                <image href={badgeURLS[this.name]} width={this.size} height={this.size}/>
            </svg>
        )
    }
}

class Vote extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    async componentDidMount() {
        var data = await fetch(`${config.hosts.INTERNAL_API}/vote/${this.props.match.params.id}`, {
            method: "GET",
            credentials: 'include'
        }).then(res => res.json()).catch(() => undefined)
        if (!data) data = {
            error: 404
        }
        this.setState(data)

        
        // fetch(`https://cdn.discord-botlist.eu/other/ad.json`, { 
        //     method: "GET",
        //     headers: {
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json',
        //     },
        //     credentials: 'include',

        // }).then(res => res.json()).then(data => this.setState({adlink: data.ad_link, adimage: data.ad_image}))

        this.setState({adlink: "https://discord-botlist.eu/get-ad", adimage: "https://cdn.discord-botlist.eu/pictures/ad.png"})
    }

    async vote() {
        const data = await fetch(`${config.hosts.INTERNAL_API}/vote/${this.props.match.params.id}`, {
            method: "POST",
            credentials: 'include'
        }).then(res => res.json()).catch(() => window.location.href = config.hosts.LOGIN)    
        this.setState(data)
    }

    render() {
        if (this.state.error) return (
            <div id="CONTENT">
                <div id="BOX-VOTED">
                    <p style={{marginLeft: "20px"}}>
                        <b style={{fontSize: "40px"}}><u>Oh no...</u></b> <br />
                        <span dangerouslySetInnerHTML={{__html: this.state.error}}></span>
                    </p>
                    <br />
                    <p id="BACK_TO" style={{textAlign: "left", marginLeft: "20px"}} onClick={() => window.location.href = `/bots/${this.state.id}`}>
                        Back to {this.state.name}
                    </p>
                </div>
            </div>
        )

        if (this.state.upvotes) return (
            <div id="CONTENT">
                <div id="BOX">
                    <center>
                        <img id="ICON" src={this.state.iconURL} style={{height: "180px", width: "180px"}} alt=""/>
                        <p id="VOTED_NONE">
                            <b style={{fontSize: "20px"}}><u>Thank you!</u></b> <br />
                            You successfully voted for <b>{this.state.name}</b><br />
                            Feel free to vote again in <b>{this.state.cooldown}</b>
                        </p>

                        <p style={{
                            fontSize: "12px",
                            position: "relative",
                            top: "20px"
                        }} id="vote-s-links">
                            Submit your own bot <a href="https://discord-botlist.eu/submit">here</a> or join our <a href="https://join.discord-botlist.eu">Discord server</a>
                        </p>
                    </center>
                    <br />
                    <p id="BACK_TO" style={{textAlign: "left", marginLeft: "20px"}} onClick={() => window.location.href = `/bots/${this.state.id}`}>
                        Back to {this.state.name}
                    </p>
                </div>
            </div>
        )

        if (!this.state.name) return (
            <div style={{paddingTop: "150px"}}>
                <Loading />
            </div>
        )

        return (
            <>
                <div id="CONTENT">
                    <div id="BOX">
                        <center>
                            <img id="ICON" src={this.state.iconURL} style={{height: "180px", width: "180px"}} alt=""/>
                            <p id="NAME">
                                {this.state.name} {convertFlags(this.state.flags)}
                            </p>
                            <button id="VOTE_BTN" onClick={() => this.vote(this.state.id)}>
                                Vote
                            </button>
                            {!getCookie("uid_id") ? <p style={{color: "red", textAlign: "left", fontSize: "15px", marginLeft: "20px"}}>
                                You need to be logged in to vote
                            </p> : ""}
                        </center>
                        <p id="BACK_TO" onClick={() => window.location.href = `/bots/${this.state.id}`}>
                            Back to {this.state.name}
                        </p>
                    </div>
                </div>
{/* 
                <div id="ad-label" style={{cursor: "pointer"}} onClick={()=>window.location.href= this.state.adlink}>
                    <div id="ad-content">
                    <img src={this.state.adimage} />
                <section id="ad-label-ad">ad</section>
                </div>
                </div> */}
            </>
        )
    }
}

function getCookie(cname){
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

function convertFlags(flags) {
    if (typeof flags == "string") flags = flags.split(" ")
    
    const finalFlags = []
    flags?.forEach(flag => {
        if (badgeURLS[flag]) {
            const badge = new Badge(flag, 30)
            finalFlags.push(badge.getHTML())
        }
    })
 
    return finalFlags
}

export default Vote