import React from 'react'
import './info.css'

export default class CertInfo extends React.Component {
    render() {
        return (
            <div id="info-window">
                <div id="head">
                    <p id="title">
                        <u>Certification</u> <img src="https://cdn.discord-botlist.eu/pictures/certified_bot.svg" />
                    </p>
                    <p id="sub-title">
                        <span id="sub-title" style={{
                            textAlign: "start"
                        }}>
                            Beside of the features and perks that are accessable for each bot and their creator, our certification program is the VIP ticket for the very special areas of dbleu (discord-botlist.eu)
                        </span>
                    </p>
                </div>

                <div id="content">
                    
                    <section id="introduction">
                        <p>
                            Explanation
                        </p>
                        The certification program was made to give special bots and their creators access to more features. But to keep it special, we have certain requirements that must be met. Exceptions are possible but need a valid reason.
                    </section>

                    <section id="benefits">
                        <p>
                            Benefits
                        </p>
                        <ul>
                            <li>
                                A life-time chance to be listed on the homepage
                            </li>
                            <li>
                                A badge for you and the bot on the whole website
                            </li>
                            <li>
                                Access to secret channel on our <a href="https://join.discord-botlist.eu">Discord server</a> for the creator and the bot
                            </li>
                            <li>
                                The ability to set a gif/webp as a banner and a custom theme and a vanity url
                            </li>
                            <li>
                                Probably more in the future
                            </li>
                        </ul>
                    </section>

                    <section id="requirements">
                        <p>
                            Requirements
                        </p>
                        <ul>
                            <li>
                                The bot needs to be online 24/7
                            </li>
                            <li>
                                Verified by Discord
                            </li>
                            <li>
                                Atleast 20 upvotes this month and 300 server
                            </li>
                            <li>
                                Listed on our website for atleast 7 days
                            </li>
                            <li>
                                Be uniqe
                            </li>
                        </ul>
                    </section>

                    <section id="apply">
                        <p>
                            Apply
                        </p>
                        To send us your application, you need to visit <b>/certify/:botid</b> and fill out the form. Please make sure to provide as much useful information as possible.<br />
                        Good luck 🍀
                    </section>

                </div>

            </div>
        )
    }
}