import React from 'react'
import config from '../../assets/config.json'

class Footer extends React.Component {
    constructor(){
        super()
        this.getCookie = this.getCookie.bind(this)
    }

    getCookie(cname){
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    render() {
        return (
            <>
                <footer>
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: "150px", width: "100%", marginBottom: "5px" }}><path d="M-21.44,46.88 C125.84,174.17 375.27,-52.78 609.47,170.22 L500.00,150.00 L0.00,150.00 Z" style={{ stroke: "none", fill: "#23272A" }}></path></svg>
                    <div id="footer-container">
                        <div id="footer-container-center">
                            <div id="footer-box">
                                <section id="footer-title">discord-botlist.eu</section>
                                <section id="desc-about">discord-botlist.eu was originally founded by three young boys to learn some web development. Over the years, it became a place for developers to talk and meet with other people.<img alt="banner_dbleu" src="https://media.discordapp.net/attachments/742296090652573716/837655034488815626/banner.png?width=1214&amp;height=683"></img></section>
                            </div>
                            <div id="footer-box">
                                <div id="inside">
                                    <section id="list-tile">Our partners</section>
                                    <section class="list">
                                        <ul>
                                            <li><a href="https://zap-hosting.com/en/">Zap-Hosting</a></li>
                                            <li><a href="https://www.caards.me">Caards</a></li>
                                        </ul>
                                    </section>
                                </div>
                            </div>
                            <div id="footer-box">
                                <div id="inside">
                                    <section id="list-tile">Important stuff</section>
                                    <section class="list">
                                        <ul>
                                            <li><a href="/info/certify">Certification</a></li>
                                            <li><a href="https://dev.discord-botlist.eu">Devpanel / API</a></li>
                                            <li><a href="https://docs.discord-botlist.eu">Documentation</a></li>
                                            <li><a href="https://join.discord-botlist.eu">Discord</a></li>
                                            <li><a href="/partner">Apply for partner</a></li>
                                            <li><a href="/terms">Terms of service</a></li>
                                            <li><a href="/privacy">Privacy Policy</a></li>
                                            <li><a href="/imprint">Imprint</a></li>
                                        </ul>
                                    </section>
                                </div>
                            </div>
                            <div id="footer-box">
                                <div id="inside">
                                    {this.getCookie("uid") ? <>
                                    <section id="list-tile"> <div id="header-hover-thing"><img className="header-login-img" src={this.getCookie("uavatar")} alt="" style={{verticalAlign:"middle"}}></img> {this.getCookie("uid")}</div> </section>
                                    <section class="list">
                                        <ul>
                                            <li><a href="/manage/me">My Profile</a></li>
                                            <li><a href={`${config.hosts.MAIN_PAGE}/logout`}>Logout</a></li>
                                        </ul>
                                    </section></>
                                    : 
                                    <> <section id="list-tile"><a style={{textDecoration:"none"}} href={config.hosts.LOGIN}>Login</a></section> </>}
                                </div>
                            </div>
                        </div>
                        <div id="copyrigth-and-after"> 
                            © 2021 Made by <a href="https://twitter.com/flamexdev">Flamex</a>, <a href="https://twitch.tv/itslptp1">LPTP1</a> and <a href="https://www.twitch.tv/brickstar12">Bendix</a> with ❤️<br/><br/>Discord-Botlist.eu is not affilated with Discord Inc.<br /><a class="copyrighted-badge" title="Copyrighted.com Registered &amp; Protected" rel="noopener" target="_blank" href="https://www.copyrighted.com/website/iHSz4jw3yTfNAeqN?url=https%3A%2F%2Fdiscord-botlist.eu%2F"><img src={{ pointerEvents: "unset"}} alt="Copyrighted.com Registered &amp; Protected" border="0" width="125" height="25" srcset="https://static.copyrighted.com/badges/125x25/03_1_2x.png 2x" src="https://static.copyrighted.com/badges/125x25/03_1.png" /></a><script src="https://static.copyrighted.com/badges/helper.js"></script>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}

export default Footer