import React from 'react'
import config from '../../../assets/config.json'

class Openreports extends React.Component{
    constructor(){
        super()
        this.state ={
           response:"",
           id: "" 
        }
        this.des = this.des.bind(this)
    }
    async componentDidMount(){
        await fetch(`${config.hosts.INTERNAL_API}/UserID`,{
            method: "GET",
              headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                credentials: 'include',
          })
          .then(res => res.json())
          .then(data => {
            console.log(data)
            this.setState({id: data.id})
        })

        await fetch(`${config.hosts.INTERNAL_API}/api/reports/open`, { 
            method: "GET",
            headers: {

                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        }).then(res => res.json()).then(data => this.setState({response: data}))
    }
    handleOnClick = (event) => {
        event.target.innerHTML = "Loading"
    }

    getCookie(cname){
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    claimbot(id){
        fetch(`${config.hosts.INTERNAL_API}/api/reports/claim?id=${id}`, {
            method: "GET",
            headers: {

                Accept: 'application/json',
                'Content-Type': 'application/json',
              },

            credentials: 'include',
        }).then(res => res.json()).then(data => {console.log(data); this.setState({response: data})})
    }

    des(dessision, id){
        console.log(dessision, id)
        fetch(`${config.hosts.INTERNAL_API}/api/reports/des/${dessision}?id=${id}`, {
            method: "GET",
            headers: {

                Accept: 'application/json',
                'Content-Type': 'application/json',
              },

            credentials: 'include',
        }).then(res => res.json()).then(window.location.reload())
        
    }

    render(){
        return(
            <>
             <strong style={{fontSize: "30px"}}>Pending Reports</strong><br />
             {this.state.response ? this.state.response?.map((e) => {if(e.accepted !== false && e.accepted !== true){ return <>
                <div id="test-container">
                    <img src={e.avatar} alt="avatar" />
                    <strong style={{marginLeft: "10px"}}>{e.name}</strong>
                    <div id="desc">{e.reason}</div>
                    <div id="owner" style={{marginLeft: "110px", color: "grey"}}>Reported by {e.reporter /*Haha Reporter lmao, versteht ihr?*/}</div><br /><br /><br />
                    {(e.claimedby === this.state.id) ? <div id="buttons"><button target="_blank" onClick={()=>window.open(e.invite, '_blank').focus()}>Invite</button><button target="_blank" onClick={()=>window.open(`https://discord-botlist.eu/bots/${e.reported}`, '_blank').focus()}>Botpage</button><button class="approve" onClick={() => this.des("approve", e.reported)}>Approve</button><button class="deny" onClick={() => this.des("deny", e.reported)}>Deny</button></div> : ((e.claimed !== true) ? <div id="buttons"><button target="_blank" onClick={ (event) => {this.claimbot(e.reported); this.handleOnClick(event)} }>Claim</button></div>: <div id="buttons"><button target="_blank" disabled>Claimed</button></div>)}
                </div>
                </>}}): "loading"}
            </>
        )
    }
}

export default Openreports