import React from 'react'
import fetch from 'cross-fetch'
import config from '../../assets/config.json'
import { ReactComponent as Loading } from '../../assets/loading.svg'

class Callback extends React.Component {
    async componentDidMount() {
        const data = await fetch(`${config.hosts.INTERNAL_API}/login${window.location.search}`, {
            method: "GET",
            credentials: "include"
        }).then(res => res.json()).catch(() => undefined)
        if (data) {
            setCookie("uid", data.username)
            setCookie("uid_id", data.id)
            setCookie("uavatar", data.avatar)
        }
        
        window.location.href = window.localStorage.getItem("lastVisited") || "/"
    }

    render() {
        return (
            <div id="CALLBACK_LOADING">
                <Loading />
            </div>
        )
    }
}

function setCookie(cname, cvalue) {
    const exdays = new Date(new Date().getTime() + 10000000000)
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export default Callback