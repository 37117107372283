import React from 'react'
import config from '../../../assets/config.json'
import Card from '../../utility/card'

class Resubmit extends React.Component{
    constructor(){
        super();
        this.state = {resub:""}
    }

    async componentDidMount(){

        await fetch(`${config.hosts.INTERNAL_API}/UserID`,{
            method: "GET",
              headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
          })
          .then(res => res.json())
          .then(data => {
            console.log(data)
            this.setState({id: data.id})
        })

        fetch(`${config.hosts.INTERNAL_API}/api/resubmit/data?id=${this.state.id}`).then(res => res.json()).then((data) => { 
            if(data.length === 0) data = undefined
            this.setState({resub: data})
        })
    }

    render(){
        return(
            <>
                <strong style={{fontSize: "30px"}}>Resubmit a bot</strong><br />
                <div class="card-container" style={{marginLeft: "-10px", display: "table-cell"}}>
                    {this.state.resub ? this.state.resub?.map(e => <Card id="edit" vanity={e.vanity} banner={e.banner} flags={e.flags} icon={e.icon} name={e.name} desc={e.desc} click={`${config.hosts.MAIN_PAGE}/submit?resubmit=true?&bot=${e.id}`} serverCount={e.server} upvotes={e.upvotes} />) : "No Bot"}
                </div>
            </>  
        )
    }
}

export default Resubmit;