import React from 'react'
import './ticketviewer.css'

class TicketViewer extends React.Component{

    // eslint-disable-next-line no-useless-constructor
    constructor(params){
        super(params)
    }

    render(){
        return(
            <>
      
                
            </>
        )
    }
}

export default TicketViewer;