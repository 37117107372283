import fetch from 'cross-fetch'
import React from 'react'
import config from '../../../assets/config.json'
import ms from 'ms'

class Item extends React.Component{
  constructor() {
    super()
    this.state = {
      left: null
    }
  }

  componentDidMount() {
    if (this.props.redeem) return
    this.setState({
      left: `${ms(new Date(this.props.expiresAt) - new Date(), {long: true})} remaining`
    })
  }

  render() {
    return (
      <div style={{border: "solid 2px gray", margin: `${window.innerWidth >= 905 ? "10px 30px" : "10px 0px"}`, padding: "5px 10px"}}>
        <p style={{margin: "0px"}}>
          <span>
            {this.props.name}
          </span> <br />
          <span style={{color: "gray"}}>
            {this.props.description}
          </span><br />
          {this.props.redeem ? 
          <>
            <br />
            <a href={`${config.hosts.MAIN_PAGE}/gift/${this.props.redeem}`} class="redeembtn">Redeem</a>
          </> : <>
            <span style={{display: "inline-flex", alignItems: "center", margin: "10px 0px"}}>
              <img src={this.props.usedFor.avatar} style={{width: "40px", borderRadius: "5px", marginRight: "15px"}} /> {this.props.usedFor.username}
            </span><br />
            <span style={{color: "gray"}}>{this.state.left}</span>
          </>}
        </p>
      </div>
    )
  }
}

export default class Inventory extends React.Component{
  constructor(){
    super()
    this.state = {
      inventory: null
    }
  }

  async componentDidMount() {
    const data = await fetch(`${config.hosts.INTERNAL_API}/inventory`, {
      credentials: 'include'
    }).then(res => res.json())

    this.setState({
      inventory: data
    })
  }

  render(){
    return(
      <>
        <strong style={{fontSize: "30px"}}>Inventory</strong><br /><br />

        {this.state.inventory && !this.state.inventory[0] ? <i style={{color: "gray"}}>Your inventory is empty. The only thing that remains is a lot of dust 🕸️</i> : ""}
        {this.state.inventory?.map(e => <Item name={e.name} description={e.description} usedFor={e.usedFor} expiresAt={e.expiresAt} redeem={e.redeem} />)}
      </>
    )
  }
}