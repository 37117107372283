import fetch from 'cross-fetch'
import React from 'react'
import config from '../../../assets/config.json'
import ms from 'ms'

class Gifts extends React.Component{
    constructor(){
        super()
        this.submitGift = this.submitGift.bind(this)
    }

    async submitGift(){
        var e = document.getElementById("SELECT_BOT")
        var type = e.options[e.selectedIndex].text
        var duration = document.getElementById("dur").value

        if (!duration) {
            document.getElementById("error").innerHTML = "No duration set"
            document.getElementById("error").style.display = "block"
            return
        } 

        if (duration < 1) {
            document.getElementById("error").innerHTML = "The time needs to be atleast 1 ms"
            document.getElementById("error").style.display = "block"
        }

        if (duration > 2147483647) {
            document.getElementById("error").innerHTML = "The time needs to be below 2147483647 ms"
            document.getElementById("error").style.display = "block"
        }

        const gift = await fetch(`${config.hosts.INTERNAL_API}/creategift`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                giftname: type,
                time: duration
            }),
            'withCredentials': true
        }).then(res => res.json())
       document.getElementById("gift_url").value = `https://gift.discord-botlist.eu/${gift.code}`
    }

    stringToMs(time) {
        var timems = 0
        const ta = time.split(" ")
        for (let i = 0; i < ta.length; i++) {
            var t = ms(ta[i])
            if (!t) return undefined
            timems += t
        }
        return timems
    }

    render(){
        return(
            <>
                <div id="gift-cr" style={{width: "50%"}}>
                    <strong style={{fontSize: "30px"}}>Create a gift</strong><br />
                    <div id="error" style={{margin: "20px 30px"}}></div>
                    <div id="input-desc" style={{marginTop: "10px", marginBottom: "3px"}}>What type of gift</div>
                    <select id="SELECT_BOT" style={{width :"35%"}}>
                        <option>CUSTOM_BORDER</option>
                        <option>BANNER_GIF</option>
                        <option>VANITY_URL</option>
                    </select>
                    <div id="input-desc" style={{marginTop: "10px", marginBottom: "3px"}}>Duration</div>
                    <input name="discord" id="s" class="input" style={{width: "30%"}} type="text" placeholder="1w 1d 1h 1s 1ms" onChange={e => {
                        const time = this.stringToMs(e.currentTarget.value)
                        if (!time) {
                            document.getElementById("error").innerHTML = "Invalid time format"
                            document.getElementById("error").style.display = "block"
                            return
                        } else {
                            document.getElementById("error").innerHTML = ""
                            document.getElementById("error").style.display = "none"
                        }
                        document.getElementById("dur").value = time
                    }}></input><br />
                    <input name="discord" id="dur" class="input" style={{width: "30%"}} type="number" disabled></input> <span style={{color: "gray", fontSize: "12px"}}>ms</span>
                    <div id="button-prof-gift" onClick={() => this.submitGift()}>Create</div>
                    <textarea id="gift_url" placeholder="Link will be placed here"></textarea>
                </div>
            </>
        )
    }
}

export default Gifts;