import React from 'react'
import '../manage.css'
import config from '../../../assets/config.json'

class Reports extends React.Component{
    constructor(){
        super()
        this.state = {
            reports: "",
            id: ""
        }
    }

    getCookie(cname){
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    async componentDidMount(){
        await fetch(`${config.hosts.INTERNAL_API}/UserID`,{
            method: "GET",
              headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
          })
          .then(res => res.json())
          .then(data => {
            this.setState({id: data.id})
        })

        fetch(`${config.hosts.INTERNAL_API}/api/reports/${this.state.id}`).then(res => res.json()).then(data => this.setState({reports: data}))
    }
    render(){
        return(
            <>
            <strong style={{fontSize: "30px"}}>Your Reports</strong><br />
            <div class="card-container">
                {this.state.reports ? this.state.reports.map(e => {
                    if(!e.claimed) {
                        this.color = "yellow"
                        this.state = "Pending"
                    }
                    if((e.claimed === true) && (!e.approved)){
                        this.color = "orange"
                        this.state = "Investigating"
                    }
                    if(e.accepted === true){
                        this.color = "green"
                        this.state = "Approved. Thank you for your report"
                    }else if(e.accepted === false){
                        this.color ="red"
                        this.state = "Denied"
                    }
                   return <>
                   <div id="rep-container">
                        <img src={e.avatar} alt="botpb" /><section id="name">{e.name}</section>
                        <br />
                            <div id="state">
                                <section id="state-title">State:</section>
                                <section id="state-state" style={{color: this.color}}>{this.state}</section>
                            </div>
                   </div>
                   </>
                }) : "No open reports"}
            </div>
            </>
        )
    }
}

export default Reports