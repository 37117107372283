import React from 'react'
import './submit.css'
import ReactIframeResizer from 'react-iframe-resizer-super';
import '../botpage/botpage.css'
import img from '../../assets/logo.png'
import config from '../../assets/config.json'
import AceEditor from "react-ace"
import "ace-builds/src-noconflict/mode-html"
import "ace-builds/src-noconflict/theme-idle_fingers"
import sanitizeHtml from "sanitize-html"
import fetch from 'cross-fetch';
var unified = require('unified')
var markdown = require('remark-parse')
var html = require('remark-html')

//Vars für Submit
var desc = "";
var error = "";

const allowedBannerTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg"
]

const statusColors = {
    "dnd": "#ED4245",
    "online": "#57F287",
    "idle": "#FEE75C",
    "offline": "#747F8D"
}

const badgeURLS = {
    "CERTIFIED": "https://cdn.discord-botlist.eu/pictures/certified_bot.svg",
    "MOD": "https://cdn.discord-botlist.eu/pictures/mod.svg",
    "ADMIN": "https://cdn.discord-botlist.eu/pictures/admin.svg",
    "PREMIUM": "https://cdn.discord-botlist.eu/pictures/premium.svg",
    "CERTIFIED_DEV": "https://cdn.discord-botlist.eu/pictures/certified_dev.svg"
}

class Tag extends React.Component {
    render() {
        return <span class="tag">{this.props.name}</span>
    }
}

class Badge {
    size
    name

    constructor(name, size) {
        this.name = name
        this.size = size
    }

    getHTML() {
        return (
            <svg className="bot-badge">
                <image href={badgeURLS[this.name]} width={this.size} height={this.size}/>
            </svg>
        )
    }
}

class DevBox extends React.Component {
    render() {
        return <span className="user" style={this.props.user.flags.length >= 1 ? {} : {paddingTop: "6.5px", paddingBottom: "6.5px"}}>{this.props.user.username}  {this.props.user.flags.length >= 1 ? <span id="badges">{convertFlags(this.props.user.flags)}</span> : <></>}</span>
    }
}

class Submit extends React.Component {
    constructor(params) {
        super(params)
        this.state = {
            botname: "Username",
            shortdesc: desc,
            tags: [],
            error1: "none",
            error_message: "",
            longdesc: "",
            banner: "",
            developer: [{
                username: getCookie("uid"),
                vanity: "a",
                flags: ["MOD"]
            }],
            prefix: "",
            id: "",
            website: "",
            supportserver: "",
            owners: "",
            status: "online",
            pfp: "https://cdn.discordapp.com/attachments/766736391819624479/862442724682498048/logo.png",
            defaultDev: {},
            bannerUrl: "https://cdn.discord-botlist.eu/pictures/nobanner.png"
        }

        this.refName = React.createRef();
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescChange = this.handleDescChange.bind(this);
        this.handleTagChange = this.handleTagChange.bind(this);
        this.handleImgChange = this.handleImgChange.bind(this);
        this.handleBannerChange = this.handleBannerChange.bind(this);
        this.handleSizeChange = this.handleSizeChange.bind(this);
        this.handlePrefixChange = this.handlePrefixChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLongdescChange = this.handleLongdescChange.bind(this);
        this.handleIdChange = this.handleIdChange.bind(this);
        this.handleInvite = this.handleInvite.bind(this)
        this.handleSupport = this.handleSupport.bind(this)
        this.handleWebsite = this.handleWebsite.bind(this)
        this.handleDevChange = this.handleDevChange.bind(this)
    }


    handleError(params) {
        if (!params) {
            // this.setState({ error1: "none" })
            return document.getElementById("error").style.display = "none"
        } else {
            // this.setState({ error1: "block" })
            this.setState({ error: params })
            return document.getElementById("error").style.display = "block"
        }
    }

    handleInvite(event) {
        this.setState({ error1: "none" })
        var value = ""
        console.log(error)
        if (!event.target.value) {
            value = ""
        }
        else {
            value = event.target.value
        }
        this.setState({ invite: value });
        this.handleError()
    }

    async handleDevChange(event) {
        this.setState({ error1: "none" })
        var value = ""
        console.log(error)
        if (!event.target.value) {
            value = ""
        }
        else {
            value = event.target.value
        }

        this.setState({ developerPlain: value });
        this.handleError()
    }


    handleWebsite(event) {
        this.setState({ error1: "none" })
        var value = ""
        console.log(error)
        if (!event.target.value) {
            value = ""
        }
        else {
            value = event.target.value
        }
        this.setState({ website: value });
        this.handleError()
    }

    handleSupport(event) {
        this.setState({ error1: "none" })
        var value = ""
        console.log(error)
        if (!event.target.value) {
            value = ""
        }
        else {
            value = event.target.value
        }
        this.setState({ supportserver: value });
        this.handleError()
    }


    handleNameChange(event) {
        this.setState({ error1: "none" })
        var value = ""
        console.log(error)
        if (!event.target.value) {
            value = "Project"
        }
        else {
            value = event.target.value
        }
        this.setState({ botname: value });
        this.handleError()
    }

    async handleBannerChange(event) {
        var value = ""

        if (!event.target.value) {
            value = ""
        }
        else {
            value = event.target.value
        }

        this.setState({ bannerUrl: await getBannerUrl(value, allowedBannerTypes), banner: value });
        this.handleError()
    }

    async handleIdChange(event) {
        var value = ""

        if (!event.target.value) {
            value = ""
        }
        else {
            value = event.target.value
        }
        this.setState({
            id: value
        })
        this.handleError()
    }

    handleImgChange(event) {
        var value = ""

        if (!event.target.value) {
            value = img
        }
        else {
            value = event.target.value
        }
        this.setState({ img: value });
        this.handleError()
    }

    handleTagChange(event) {
        var value = ""
        if (!event.target.value) {
            value = ""
        }
        else {
            value = event.target.value
        }

        var tags = []
        value.split(", ").forEach(e => {
            if (!tags.includes(e)) tags.push(e)
        })

        this.setState({ tags: tags });
        this.handleError()
    }

    handleDescChange(event) {
        var value = ""
        if (!event.target.value) {
            value = ""
        }
        else {
            value = event.target.value
        }
        this.setState({ shortdesc: value })
    }

    handleSizeChange(event) {
        var value = ""
        if (!event.target.value) {
            value = "0"
        }
        else {
            value = event.target.value
        }
        this.setState({ size: value })
    }

    handlePrefixChange(event) {
        var value = ""
        if (!event.target.value) {
            value = ""
        }
        else {
            value = event.target.value
        }
        this.setState({ prefix: value })
    }

    handleWorkChange(event) {
        var value = ""
        if (!event.target.value) {
            value = "0"
        }
        else {
            value = event.target.value
        }
        this.setState({ work: value })
    }

    async handleLongdescChange(event) {
        this.refName.current.editor.getValue()
        var value = ""
        if (!this.refName.current.editor.getValue()) {
            value = ""
        }
        else {
            value = this.refName.current.editor.getValue()
        }
        this.setState({ longdesc: await this.getFinalLongdesc(this.refName.current.editor.getValue()), longdescPlain: value })
    }


    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    async componentDidMount() {
        fetch(`${config.hosts.INTERNAL_API}/first`, {
            credentials: "true"
        }).then(res => res.json()).then(data => {
            this.setState({
                first: data.first
            })
        })

        const urlParams = new URLSearchParams(window.location.search)

        if (urlParams.get("resubmit")) fetch(`${config.hosts.INTERNAL_API}/botdata?id=${urlParams.get("bot")}`, {
            credentials: 'include'
        }).then(res => res.json()).then(async data => {
            if (!data || !data.edit) return 
            this.setState({
                pfp: data.avatarBild,
                id: data.botid,
                banner: data.banner,
                bannerUrl: await getBannerUrl(data.banner, data.flags),
                flags: data.flags,
                invite: data.invite,
                developer: data.developer,
                shortdesc: data.shortdesc,
                botname: data.botname,
                developerPlain: data.developer.map(e => e.id).join(", "),
                tags: data.tags,
                vanity: data.vanity,
                longdescPlain: data.longdesc,
                website: data.website,
                supportserver: data.supportserver,
                border: data.border,
                upvotes: data.upvotes.length,
                servercount: data.server_count
            })

            this.getFinalLongdesc(data.longdesc).then(desc => {
                this.setState({longdesc: desc})
            })
        })
    }

    handleSubmit(event) {
        event.preventDefault();    
        const urlParams = new URLSearchParams(window.location.search)    

        if (this.state.shortdesc.length === 0) {
            return this.handleError("The 'Describe your project' field is empty")
        }
        if (document.getElementById("shortdesc").value.length > 100) {
            return this.handleError("The 'Describe your project' field is too long (max. 100)")
        }

        if (this.state.tags.length === 0) {
            return this.handleError("Please provide at least one tag")
        }

        if (this.state.prefix.length === 0) {
            return this.handleError("The 'Prefix' field is empty")
        }
        if (this.state.prefix.length >= 6) {
            return this.handleError("Your prefix is too long. Make sure it's shorter than 6 chars")
        }

        if (!this.validURL(this.state.invite)) return this.handleError("This is not a valid url")

        if ((300 - this.state.longdesc.length) > 0) {
            return this.handleError(`The 'Longdescription' is too short (${300 - this.state.longdesc.length} left)`)
        }        
        
        if(this.state.invite.includes("permissions=8")) return this.handleError("Please dont use an invite with admin permissions. Please create a new one.")

        fetch(`${config.hosts.INTERNAL_API}/api/submit`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                website: this.state.website,
                support: this.state.supportserver,
                prefix: this.state.prefix,
                longdescription: this.state.longdescPlain,
                shortdesc: this.state.shortdesc,
                botid: this.state.id.replaceAll(" ", ""),
                tags: this.state.tags,
                invite: this.state.invite,
                owners: this.state.developerPlain?.split(", ") || "",
                banner: this.state.banner,
                resubmit : urlParams.get("resubmit") ? true : false,
                inviteCode: document.getElementById("invitationCode").value
            }),
            credentials: 'include'
        }).then(res => res.json()).then(function (res) {
            if (!res.error) {
                window.location.href = `/bots/${res.id}`
            } else {
                document.getElementById("error").innerHTML = res.error
            }
        });
    }

    validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    async getFinalLongdesc(desc) {
        return new Promise(resolve => {
            unified()
            .use(markdown)
            .use(html)
            .process(desc, (err, parsedHTML) => {
                resolve(this.state.flags?.includes("TAGS_BYPASS") ? String(parsedHTML) : sanitizeHtml(String(parsedHTML), {
                    allowedTags: [
                        'h1',
                        'h2',
                        'h3',
                        'h4',
                        'h5',
                        'h6',
                        'em',
                        'strong',
                        'code',
                        'ul',
                        'li',
                        'a',
                        'img',
                        'blockquote',
                        'code',
                        'br',
                        'table',
                        'tr',
                        'td',
                        'th',
                        'style',
                        'iframe',
                        'p',
                        'hr'
                    ],
                    allowedAttributes: {
                        '*': [ 'style', 'br' ],
                        'a': [ 'href' ],
                        'img': [ 'alt', 'src', 'title' ],
                        'iframe': [ 'href', 'src' , 'br' ]
                    },
                    disallowedTagsMode: "discard"
                }))
            })
        })
    }

    render() {
        document.body.style.backgroundColor = "#dadada"

        if (!getCookie("uid_id")) return <>{window.location.href = config.hosts.LOGIN}</>
        
        return (
            <>
                <div id="all-container">
                    <div id="form-container">
                        <div id="form-container-inputs">
                            <div id="error">{this.state.error}</div>
                            <div id="form-container-title">
                                Tell us something about your project
                            </div>
                            <form method="POST" action="/lala" onSubmit={this.handleSubmit}>
                                <label>Your Bots id*</label>
                                <input id="botid" value={this.state.id} autocomplete="off" placeholder="47538945679384569" onChange={this.handleIdChange}></input>
                                <label>Describe your bot*</label>
                                <input id="shortdesc" value={this.state.shortdesc} autocomplete="off" placeholder="This project is..." onChange={this.handleDescChange}></input>
                                <label>Tags*</label>
                                <input id="tags" value={this.state.tags?.join(", ")} autocomplete="off" placeholder="Moderation, Dashboard..." onChange={this.handleTagChange}></input>
                                <label>Your bots prefix*</label>
                                <input id="prefix" value={this.state.prefix} autocomplete="off" placeholder="!" onChange={this.handlePrefixChange}></input>
                                <label>Your invite link*</label>
                                <input id="invite" value={this.state.invite} autocomplete="off" placeholder="https://discord.com/api/oauth2/authorize?client_id=842448478536728646&permissions=8&scope=bot" onChange={this.handleInvite}></input>
                                <label>Your website link</label>
                                <input id="website" value={this.state.website} autocomplete="off" placeholder="https://discord-botlist.eu" onChange={this.handleWebsite}></input>
                                <label>Your support server invite</label>
                                <input id="support" value={this.state.supportserver} autocomplete="off" placeholder="https://discord.gg/su33SK2cHW" onChange={this.handleSupport}></input>
                                <label>Add additional Owners</label>
                                <input id="owners" value={this.state.developerPlain} autocomplete="off" placeholder="681424352599736327, 821472922140803112" onChange={this.handleDevChange}></input>
                                <label>Link a Banner image</label>
                                <input id="banner" value={this.state.banner} autocomplete="off" placeholder="https://i.imgur.com/eibibZy.gif" onChange={this.handleBannerChange}></input>

                                <label>Invite code</label>
                                <p style={{
                                    color: "gray",
                                    fontSize: "13px",
                                    margin: "0px"
                                }}>You got invited by a friends and you got a code? Make sure to provide it to get an awsome <u style={{cursor: "pointer"}} onClick={() => window.open(`${config.hosts.MAIN_PAGE}/manage/me#inventory`)}>gift</u><br />This is useless if you have already submitted a bot</p>
                                <input id="invitationCode" autocomplete="off" placeholder="EVBZU75"></input>

                                <label>Describe your project in at least 300 chars</label>
                                {/* <textarea id="longdescription" autocomplete="off"  onChange={this.handleLongdescChange} placeholder="Good html knowladge..."></textarea> */}
                                <AceEditor
                                    mode="html"
                                    theme="idle_fingers"
                                    name="longdescription"
                                    editorProps={{ $blockScrolling: true, }}
                                    maxLines={Infinity}
                                    onChange={this.handleLongdescChange}
                                    ref={this.refName}
                                    placeholder="**Markdown** and <p>HTML</p> supported"
                                    value={this.state.longdescPlain?.replaceAll("<br />", "\n").replaceAll("<br>", "\n")}
                                />

                                <button>Submit</button>
                            </form>
                        </div>
                    </div>
                    <div id="right-cont">
                        
                        <div className="card" style={{marginLeft: "auto", marginRight: "auto"}}>
                            <div className="bannerparent banner">
                                <img src={this.state.bannerUrl} className="banner" alt="" />
                            </div>
                            <div className="iconbg">
                                <img src={this.state.pfp} className="icon" alt="" />
                            </div>
                            <div className="bot-infos">
                                <p className="name">{this.state.botname}</p>
                                    <textarea className="shortdesc" disabled value={this.state.shortdesc}></textarea>
                                <p className="bottom">
                                    <span className="guilds">5342 server</span>
                                    <span className="votes">93 upvotes</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* BOTPAGE PREVIEW */}
                <div id="box-preview">
                <div className="box preview" id="infos-general" style={{marginTop: "0px"}}>
                    <div id="bannerImg" style={{backgroundImage: `url(${this.state.bannerUrl})`}}></div>
                    <div id="bannerGradient"></div>
                    <div id="head">
                        <img id="pfp" alt="pfp" src={this.state.pfp} style={{borderColor: statusColors[this.state.status]}} />
                        <div>
                            <span id="name">
                                {this.state.botname}
                            </span> <br />
                            <span id="shortdesc" style={{marginTop: "0px"}}>
                                {this.state.shortdesc}
                            </span> <br />
                            <span id="tags">
                                {this.state.tags.filter(tag => !["", ","].includes(tag)).map(tag => <Tag name={tag} />)}
                            </span>
                        </div>
                    </div>
                    <span style={{color: "#5865F2"}}>Made by</span>
                    <div id="devs">
                        {this.state.developer.map(user => <DevBox user={user} />)}
                    </div>
                    <div id="buttons">
                        <div className="button">
                            Invite
                        </div>
                        <div className="button">
                            Vote (93)
                        </div>
                        {this.state.supportserver ?
                            <div className="button additional">
                                Support
                            </div>
                        :<></>}
                        {this.state.website ?
                            <div className="button additional">
                                Website
                            </div>
                        :<></>}
                    </div>
                        <div id="stats">
                            <span>
                                Used in 5342 server
                            </span>
                        </div>
                    </div>

                    <br />
                    <br />
                    <br />
                    <br />
                    {/* pls dont hate me. there wasn't another way */}

                    <div className="box preview" id="longdesc-frame" style={{marginTop: "0px"}}>
                    <ReactIframeResizer iframeResizerOptions={{ checkOrigin: false }}>
                        <span style={{color: '#ffffff', fontFamily: "'Work Sans', sans-serif", display:"block", fontWeight: 500, overflow: "hidden", width: "90%"}} dangerouslySetInnerHTML={{__html: `<style>code{color: pink} table, a{color: white;  border-collapse: collapse;} a{color: lightblue; transition: 200ms ease} a:hover{color: #5865F2} th{text-align:left;} td, th{padding: 8px 5px; border: 3px solid #363c41; margin-top: -1px;} </style><base target="_blank" /> ${this.state.longdesc}`}} />
                    </ReactIframeResizer>
                </div>
                </div>
            </>
        )
    }
}

function getCookie(cname){
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
}

function convertFlags(flags) {
    if (typeof flags == "string") flags = flags.split(" ")

    const finalFlags = []
    flags?.forEach(flag => {
        if (badgeURLS[flag]) {
            const badge = new Badge(flag, 20)
            finalFlags.push(badge.getHTML())
        }
    })

    return finalFlags
}

async function getBannerUrl(url, allowedTypes) {
    var data = typeof url == "string" ? await fetch(url).then(res => res.blob()).then(data => data).catch(() => undefined) : undefined
    return allowedTypes.includes(data?.type) ? url : "https://cdn.discord-botlist.eu/pictures/nobanner.png"
}

export default Submit