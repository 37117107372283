import React from 'react'
import config from '../../assets/config.json'

class Logout extends React.Component {
    async componentDidMount() {

        await fetch(`${config.hosts.INTERNAL_API}/logout`, {
            method: "GET",
            credentials: "include"
        }).then(res => {
            if(res.status === 200) {
                setCookie("uid", "")
                setCookie("uid_id", "")
                setCookie("uavatar", "")
                
                window.location.href = window.localStorage.getItem("lastVisited") || "/"
            }
        })
    }

    render() {
        return <></>
    }
}

function setCookie(cname, cvalue) {
    const exdays = new Date()
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export default Logout