import React from 'react'
import config from '../../assets/config.json'

const badgeURLS = {
    "CERTIFIED": "https://cdn.discord-botlist.eu/pictures/certified_bot.svg",
    "MOD": "https://cdn.discord-botlist.eu/pictures/mod.svg"
}

class Badge {
    size
    name

    constructor(name, size) {
        this.name = name
        this.size = size
    }

    getURL() {
        return this.url
    }
    getHTML() {
        return (
            <svg style={{position: "relative", top: "17px", "marginRight": "-10px"}}>
                <image href={badgeURLS[this.name]} width={this.size} height={this.size / 10 * 7}/>
            </svg>
        )
    }
}

class Card extends React.Component {
    constructor(props) {
        super(props)

        var flags = this.props.flags
        if (typeof flags == "string") flags = flags.split(" ")
        this.state = {
            flags: flags
        }
    }

    render() {
        if (this.props.dummy === "true") {
            return (
                <div className="card" style={{width: "300px", overflow: "hidden"}}>
                    <div className="CARD_DUMMY_BG">
                        .
                    </div>
                </div>
            )
        }
        else if(this.props.empty === "true"){
            return (
                <div className="card" style={{width: "300px", overflow: "hidden"}}>
                    <div className="bannerparent">
                        <img src={"https://cdn.discord-botlist.eu/pictures/nobanner.png"} className="banner" alt="" />
                    </div>
                    <div className="iconbg">
                        <img src={"https://www.mrbrickstar.de/fragezeichen.png"} className="icon" alt="?" />
                    </div>
                    <div className="bot-infos">
                        <p className="name">Empty</p>
                            <textarea className="shortdesc" disabled value="This place is empty, contact us to get his place :)"></textarea>
                    </div>
                </div>
            )
        }

        return (
            <div id={this.props.id} style={{borderColor: this.props.flags?.includes("CUSTOM_CARD") || this.props.flags?.includes("CERTIFIED") ? this.props.borderColor : "transparent", width: "300px", float: "left", marginRight: "30px"}} className="custom-card card" onClick={() => {
                    if (this.props.click) return window.location.href = this.props.click
                    window.location.href =  `/bots/${this.props.vanity || this.props.id}`
                }}>
                <div className="bannerparent banner">
                    <img src={this.props.banner !== "https://cdn.discord-botlist.eu/pictures/nobanner.png" ? this.props.banner : "https://cdn.discord-botlist.eu/pictures/nobanner.png"} className="banner" alt="" />
                </div>
                <div className="iconbg">
                    <img src={this.props.icon} className="icon" alt="" />
                </div>
                <div className="bot-infos">
                    <p className="name">{this.props.name} {convertFlags(this.props.flags)}</p>
                        <textarea className="shortdesc" disabled value={this.props.desc}></textarea>
                    <p className="bottom">
                        {this.props.edit ? 
                        <>
                            <a className="edit" href={`${config.hosts.MAIN_PAGE}/edit/${this.props.vanity}`}>EDIT</a>
                            <a className="delete" href={`${config.hosts.MAIN_PAGE}/delete/${this.props.vanity}`}>DELETE</a>
                        </>
                        :<>
                            <span className="guilds">{this.props.serverCount} server</span>
                            <span className="votes">{this.props.upvotes} upvotes</span>
                        </>
                    }
                    </p>
                </div>
            </div>
        )
    }
}

function convertFlags(flags) {
    if (typeof flags == "string") flags = flags.split(" ")
    
    const finalFlags = []
    flags.forEach(flag => {
        if (badgeURLS[flag]) {
            const badge = new Badge(flag, 25)
            finalFlags.push(badge.getHTML())
        }
    })

    return finalFlags
}

export default Card