import React from 'react'
import './manage.css'
import config from '../../assets/config.json'

import Reports from './pages/report'
import OpenReports from './pages/openreports'
import Queue from './pages/queue'
import EditBio from './pages/editprofile'
import Gifts from './pages/gift'
import MyBots from './pages/mybots'
import Resubmit from './pages/resubmit'
import Inventory from './pages/inventory'
import fetch from 'cross-fetch'
import {ReactComponent as Dropdown} from '../../assets/dropdown.svg'

import PopOut from '../utility/popup'

class Pannel extends React.Component{
    bio
    discord
    website
    
    constructor(params){
        super(params);
        this.getCookie = this.getCookie.bind(this)
        this.changeSite = this.changeSite.bind(this)
        this.handleOnClick = this.handleOnClick.bind(this)
        this.submitProfileEdit = this.submitProfileEdit.bind(this)
        this.togglemenu = this.togglemenu.bind(this)
        this.currpage = 0;
        this.state = {
            current: <EditBio />, 
            list: <></>, 
            userBots: undefined,
            resub: undefined,
            needtest: undefined,
            popup: "",
            type: "",
            page: "Welcome"
        }

        this.pages = [
            "Your Profile",
            "Your Bots",
            "Inventory",
            "Resubmit a bot",
            "Your Reports"
        ]
    }

    togglemenu(){
        var menu = document.getElementById("menu-mobile")
        console.log(this.state.isMod)
        if(this.state.isMod === true){
            if(menu.style.height === "0px"){
                menu.style = "height: 365px";
            }else{
                menu.style = "height: 0px"
            }
        }else{
            if(menu.style.height === "0px"){
                menu.style = "height: 210px";
            }else{
                menu.style = "height: 0px"
            }
        }
    }

    getCookie(cname){
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }
    
    toggleCheck(checkbox){
        var checkboxes = document.getElementsByClassName('menu-mobile')
        checkboxes.forEach((item) => {
            if (item !== checkbox.target){
                item.checked = false
            }
        })
    }

    toggleReportPopup(type, botid, state) {
        console.log("toggled")
        this.setState({type: type})
        if(type === "Approve"){
            this.setState({popup: <>
            <h3>Approve</h3>
                <p>
                    Anything you would like to add? If not, click approve
                </p>
                <textarea id="reportDescription" placeholder="Bla bla bla write something in here"></textarea>
                  <button onClick={() => this.submitReport(botid, document.getElementById("reportDescription").value)}>
                     {type}
                  </button>
            </>
            })
        }else if(type === "Deny"){
            this.setState({popup: <>
                <h3>Deny</h3>
                    <p>
                        Reason:
                    </p>
         
                    <input type="checkbox" name="check" id="offline" onClick={(e)=>this.toggleCheck(e)} /><label>Bot was offline</label><br />
                    <input type="checkbox" name="check" id="help" onClick={(e)=>this.toggleCheck(e)}/><label>Help command not working</label><br />
                    <input type="checkbox" name="check" id="went-offline" onClick={(e)=>this.toggleCheck(e)}/><label>Bot went offline durring testing</label><br />
                    <input type="checkbox" name="check" id="spam" onClick={(e)=>this.toggleCheck(e)}/><label>Longdesc filled with spam</label><br />
                    <p>Others:</p>
                    <textarea id="reportDescription" placeholder="Bla bla bla write something in here"></textarea>
                  <button onClick={() =>{ 
                       var inputs = [document.getElementById("offline").checked, document.getElementById("help").checked, document.getElementById("went-offline").checked, document.getElementById("spam").checked, document.getElementById("reportDescription").value]
                       for(var i = 0; i < inputs.length; i++){
                        //    console.log("INPUT ", inputs)
                           if(inputs[i] === true)
                           {
                              return this.submitReport(botid, Number(i+1))
                           }else if(i === (inputs.length -1) && inputs[i] !== undefined){
                            return this.submitReport(botid, inputs[i])
                           }
                       }
                    }}>
                     {type}
                  </button>
                </>
            })
        }else{
            return;
        }

        // if (state === undefined) {
        //     document.getElementById("report-popup-bg").style.display = "block"
        //     document.getElementById("report-popup").style.display = "block"
        //     setTimeout(()=>{
        //         document.getElementById("report-popup-bg").style.opacity = 1
        //         document.getElementById("report-popup").style.opacity = 1
        //     },100)
        // } else {
        //     document.getElementById("report-popup-bg").style.opacity = 0
        //     document.getElementById("report-popup").style.opacity = 0
        //     setTimeout(()=>{
        //         document.getElementById("report-popup-bg").style.display = "none"
        //         document.getElementById("report-popup").style.display = "none"
        //     },600)
        // }
     }

      submitProfileEdit() {
        fetch(`${config.hosts.INTERNAL_API}/api/user/edit`, {
            method: "POST",
            headers: {
                'bio': document.getElementById("bio").value,
                'discord': document.getElementById("discord").value,
                'website': document.getElementById("website").value,
                'userid': this.getCookie("uid_id")
            }
         }).then(() => {
            window.location.reload()
        })
     }
  
     async submitReport(botid, reason) {
        await fetch(`${config.hosts.INTERNAL_API}/mod/${this.state.type}/${botid}`, {
           method: "POST",
           headers: {
            'Content-Type': 'application/json'
          },
           body: JSON.stringify({
              "reason": reason
           })
        }).then(res => res.json())
        .then(async (res) => {
            if(res.status === 200){
                this.toggleReportPopup(this.state.type, 0, "close")
            }
        })
        
     }

    handleOnClick = (event) => {
        event.target.innerHTML = "Loading"
    }

    async componentDidMount(){
        await fetch(`${config.hosts.INTERNAL_API}/UserID`,{
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include'
          })
          .then(res => res.json())
          .then(data => {
            if(data.isMod) {
                this.pages.push("Queue", "Pending Reports", "Create Gift")
            }
            this.setState({id: data.id, isMod: data.isMod})
        })
        fetch(`${config.hosts.INTERNAL_API}/api/botUser?id=${this.state.id}`,{method:"GET"}).then(res => res.json()).then(data => {
            this.setState({userBots: data})
        })
        fetch(`${config.hosts.INTERNAL_API}/api/resubmit/data?id=${this.state.id}`).then(res => res.json()).then((data) => { 
            if(data.length === 0) data = undefined
            this.setState({resub: data})
        })
        fetch(`${config.hosts.INTERNAL_API}/api/queue`).then(res => res.json()).then((data) => { 
            if(data.length === 0) data = undefined
            this.setState({needtest: data},() => this.forceUpdate())
        })

        switch (window.location.hash) {
            case "#profile":
                this.changeSite(1)
                break;
            
            case "#bots":
                this.changeSite(2)
                break;

            case "#inventory":
                this.changeSite(3)
                break;
        
            case "#resubmit":
                this.changeSite(4)
                break;

            case "#reports":
                this.changeSite(5)
                break;

            case "#queue":
                this.changeSite(6)
                break;

            case "#gifts":
                this.changeSite(8)
                break;

            default:
                this.changeSite(1)
                break;
        }
    }

    async changeSite(page){
        // this.togglemenu()
        let isMobile = window.matchMedia("only screen and (max-width: 905px)").matches;
        if(isMobile === true) this.togglemenu()
        this.currpage = page
        this.setState({type: this.state.type})
        if(page === 1){
            this.setState({current: <EditBio />, page: "Your Profile" })
        }else if(page === 2){
            this.setState({current: <MyBots />, page: "My Bots"})
        }else if(page === 3){
            this.setState({current: <Inventory />, page: "Inventory"})
        }else if(page === 4){
            this.setState({current: <Resubmit />, page: "Resubmit a bot"})
        }else if(page === 5){
            this.setState({current: <Reports />, page:  "Your Reports"})
        }else if(page === 6){
            await this.setState({current: <Queue />, page: "Queue"})
        }else if(page === 7){
            this.setState({current: <OpenReports />, page: "Open Reposts"})
        }else if(page === 8){
            this.setState({current: <Gifts />, page: "Create Gift"})
        }
    }

    render(){
        if (!this.getCookie('uid')) return <>{window.location.href = "/login"}</>
        return(
            <>
                <div id="manage-container">
                    <div id="list">
                        <div id="current" onClick={() => this.togglemenu()}>
                            <section class="title">{this.state.page}</section><Dropdown /></div>
                        <ul id="menu-mobile">
                            {this.pages.map((name, i) =>{ return <li onClick={()=>{this.changeSite(i+1)}}>{name}</li>})}
                        </ul>
                    </div>
                    <div id="content-manage">
                        {this.state.current}
                    </div>
                </div>
            </>
        )
    }
}

export default Pannel