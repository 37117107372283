// DAS HIER IST AUS https://github.com/mrbrickstar/Planer (eveplaber.net) GEKLAUT.
// Keine Verwendung ohne mein Einverständniss irgendwo anders. Danke

import { useEffect, useState } from "react"
import React from "react"
import './popout.css'

class PopOut extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            on: props.content ? true : false,
            content: props.content
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.content != null || nextProps.content != undefined) {
            if(nextProps.content === this.state.content) return
            this.setState({
                on: !this.state.on,
                content: nextProps.content
            })
        }else{
            this.setState({
                on: false,
                content: null
            })
        }
    }

    render() {
        return (
            <div id="container-lol" style={this.state.on === true ? {opacity: "100", display: "block"} : {display: "none"}} >
                <div id="popout" >
                <div id="background" onClick={() => this.setState({on: false})}></div>
                    <div id="container" className="bg-gray-700 p-10 rounded z-[100] m-10 sm:m-0 max-w-[400px] max-h-[90vh] sm:max-w-none overflow-y-auto">
                        {this.state.content || "This is empty for some reason. Please contact the admin."}
                    </div>
                </div>
            </div>
        )
    }
}

export default PopOut;