import React from 'react'
import './search.css'
import fetch from 'cross-fetch'
import '../../cards.css'
import Card from '../utility/card'
import config from '../../assets/config.json'

class Search extends React.Component {
    constructor() {
        super()
        this.state = {
            bots: null
        }
    }

    async componentDidMount() {
        const data = await fetch(`${config.hosts.INTERNAL_API}/search`, {
            method: "GET",
            headers: {
                'query': new URLSearchParams(window.location.search).get("q")
            }
        }).then(res => {
            if (res.status == 200) return res.json()
            this.setState({
                empty: true
            })
        })
        this.setState({bots: data})
    }

    render() {
        return (
            <>
                <div id="headline-all">
                    Search results
                    <section id="sub-headline">{new URLSearchParams(window.location.search).get("q")}</section>
                </div>
                <div className="search-parent">
                    {this.state.empty ? <center><i>No results found</i></center> : ""}
                    <div className="card-group-search">
                        {this.state.bots ? this.state.bots.sort((a, b) => {
                            if (a.name.toLowerCase().includes(new URLSearchParams(window.location.search).get("q"))) {
                                return -1
                            } else {
                                return 1
                            }
                        }).map(e => <Card borderColor={e.border_color} id={e.id} vanity={e.vanity} banner={e.banner} flags={e.flags} icon={e.icon} name={e.name} desc={e.desc} serverCount={e.server} upvotes={e.upvotes} />) : this.state.empty ? "" : <>
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                            <Card dummy="true" />
                        </>}
                    </div>
                </div>
            </>
        )
    }
}

export default Search