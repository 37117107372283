import React from 'react'
import ReactIframeResizer from 'react-iframe-resizer-super';
import sanitizeHtml from "sanitize-html"
import { ReactComponent as Loading } from '../../assets/loading.svg'
import './botpage.css'
import config from '../../assets/config.json'
import Error404 from '../404/404'

var unified = require('unified')
var markdown = require('remark-parse')
var html = require('remark-html')

const statusColors = {
   "dnd": "#ED4245",
   "online": "#57F287",
   "idle": "#FEE75C",
   "offline": "#747F8D"
}

const badgeURLS = {
   "CERTIFIED": "https://cdn.discord-botlist.eu/pictures/certified_bot.svg",
   "MOD": "https://cdn.discord-botlist.eu/pictures/mod.svg",
   "ADMIN": "https://cdn.discord-botlist.eu/pictures/admin.svg",
   "PREMIUM": "https://cdn.discord-botlist.eu/pictures/premium.svg",
   "CERTIFIED_DEV": "https://cdn.discord-botlist.eu/pictures/certified_dev.svg"
}

class Badge {
   size
   name

   constructor(name, size) {
      this.name = name
      this.size = size
   }

   getHTML() {
      return (
         <svg className="bot-badge">
            <image href={badgeURLS[this.name]} width={this.size} height={this.size}/>
         </svg>
      )
   }
}

class Tag extends React.Component {
   render() {
      return <span class="tag" onClick={() => window.location.href = `/search?q=${this.props.name.toLowerCase().replaceAll(" ", "_")}`}>{this.props.name}</span>
   }
}

class DevBox extends React.Component {
   render() {
      return <span className="user" style={this.props.user.flags.length >= 1 ? {} : {paddingTop: "6.5px", paddingBottom: "6.5px"}} onClick={() => window.location.href = `/users/${this.props.user.vanity}`}>{this.props.user.username}  {this.props.user.flags.length >= 1 ? <span id="badges">{convertFlags(this.props.user.flags)}</span> : <></>}</span>
   }
}

class Alert extends React.Component{

  constructor(alert){
      super(alert)
     
      if(alert === "none"){
         alert = {type: undefined, text: undefined}
      }
      this.alert = alert.alert
   }
   render(){
      if (this.alert.type === "outdated") return <div id="content-box" class="outdated">⚠️ | This bot has been deprecated and will not longer receive updates</div>
      if (this.alert.type === "degraded") return <div id="content-box" class="degraded">⚠️ | This bot has some performance issues right now. This message will disappear once this has been resolved</div>
      if (this.alert.type === "news") return <div id="content-box" class="news">📣 | {this.alert.text}</div>
      return <></>
   }
}

class Botpages extends React.Component {
   constructor(props) {
      super(props)
      this.state = { }
   }

   async componentWillMount() {
      var botid = this.props.match.params.id;
      const data = await fetch(`${config.hosts.INTERNAL_API}/botdata?id=${botid}`, { credentials: "include" }).then(res => res.json()).catch(() => undefined)
      if (!data) return this.setState({error: 404})
      if (data.denied === true) return this.setState({error: 404})
      if (data.accepted_report === true) return this.setState({error: 404})
      this.setState(data)
      this.setState({
         finalLongdesc: await this.getFinalLongdesc(data.longdesc)
      })
      if (!window.localStorage.getItem(`hideCertWindow-${data.id}`)) {
         if (data.applyCert) document.body.style.marginTop = "25px"
         if (data.applyCert && window.innerWidth < 555) document.body.style.marginTop = "60px"
      }
   }

   async getFinalLongdesc(desc) {
      return new Promise(resolve => {
         unified()
         .use(markdown)
         .use(html)
         .process(desc, (err, parsedHTML) => {
            resolve(this.state.flags?.includes("TAGS_BYPASS") ? String(parsedHTML) : sanitizeHtml(String(parsedHTML), {
               allowedTags: [
                  'h1',
                  'h2',
                  'h3',
                  'h4',
                  'h5',
                  'h6',
                  'em',
                  'strong',
                  'code',
                  'ul',
                  'li',
                  'a',
                  'img',
                  'blockquote',
                  'code',
                  'br',
                  'table',
                  'tr',
                  'td',
                  'th',
                  'style',
                  'iframe',
                  'p',
                  'hr'
               ],
               allowedAttributes: {
                  '*': [ 'style', 'br' ],
                  'a': [ 'href' ],
                  'img': [ 'alt', 'src', 'title' ],
                  'iframe': ['*']
               },
               disallowedTagsMode: "discard"
            }))
         })
      })
   }

   render(){
      document.getElementById("root").style.display = "block"
      if (this.state.error === 404) return <Error404 />
      if (!this.state.botname) return <div style={{paddingTop: "100px", height: "auto"}}><Loading /></div>
      document.title = `${this.state.botname} | discord-botlist.eu`
      return (
         <>
            {this.state.applyCert && !window.localStorage.getItem(`hideCertWindow-${this.state.id}`) ? <div id="cert-req-parent">
               <span style={{
                  position: "absolute",
                  right: "10px",
                  color: "rgb(255, 255, 255)",
                  cursor: "pointer",
                  padding: "3px",
                  height: "15px",
                  width: "15px",
                  padding: "5px",
                  paddingTop: "0px",
                  paddingBottom: "10px",
                  border: "2px solid white",
                  borderRadius: "5px",
                  marginTop: "-3px",
               }} onClick={() => {
                  document.getElementById("cert-req-parent").remove()
                  document.body.style.marginTop = "0px"
                  window.localStorage.setItem(`hideCertWindow-${this.state.id}`, true)
               }}>
                  x
               </span>
               Already heard about our certification program? &nbsp; <button style={{
                  borderRadius: "3px",
                  padding: "0px 3px",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                  position: "relative",
                  top: "-1px"
               }} onClick={() => window.location.href = "/info/certify"} id="cert-req">Learn more</button>
            </div> : ""}

            <br />
            <br />
            <br />
            <br />
            <br />
            {/* pls dont hate me. there wasn't another way
               WHY FLAMEX WHY
               sorry :c
            */}

            {this.state.verified === false ? <div id="unapproved"><div id="text">Oli has noticed, that this bot was not approved yet. Be careful, the bot could be dangerous</div></div> : ""}
            {this.state.denied === true ? "" : <Alert alert={this.state.alert ? this.state.alert : "none"}/>}

            <div className="box" id="infos-general">
               <div id="bannerImg" style={{backgroundImage: `url(${this.state.displayBanner})`}}></div>
               <div id="bannerGradient"></div>
               <div id="head">
                  <img id="pfp" alt="avatar" src={this.state.avatarBild} style={{borderColor: statusColors[this.state.status]}} />
                  <div>
                     <span id="name">
                        {this.state.botname} {convertFlags(this.state.flags)}
                     </span> <br />
                     <span id="shortdesc">
                        {this.state.shortdesc}
                     </span> <br />
                     <span id="tags">
                        {this.state.tags.filter(tag => !["", ", "].includes(tag)).map(tag => <Tag name={tag} />)}
                     </span>
                  </div>
               </div>
               <span style={{color: "#5865F2"}}>Made by</span>
               <div id="devs">
                  {this.state.developer.map(user => <DevBox user={user} />)}
               </div>
               <div id="buttons">
                  <div className="button" onClick={() => window.location.href = this.state.invite}>
                     Invite
                  </div>
                  <div className="button" onClick={() => window.location.href = `/vote/${this.props.match.params.id}`}>
                     Vote
                  </div>
                  {this.state.supportserver ? 
                     <div className="button additional" onClick={() => window.location.href = this.state.supportserver}>
                        Support
                     </div>
                  :<></>}
                  {this.state.website ? 
                     <div className="button additional" onClick={() => window.location.href = this.state.website}>
                        Website
                     </div>
                  :<></>}
                  {this.state.edit ? 
                     <div className="button" id="edit" onClick={() => window.location.href = `/edit/${this.props.match.params.id}`}>
                        Edit
                     </div>
                  :<></>}
                  {getCookie("uid_id") && !this.state.edit ? 
                     <div className="button" id="report" onClick={() => window.location.href = `/report/${this.props.match.params.id}`}>
                        Report
                     </div>
                  :<></>}
               </div>
               <div id="stats">
                  <span>
                     {this.state.server_count !== "N/A" ? <><b>{this.state.server_count}</b> server &nbsp; | &nbsp; </> : ""} <b>{this.state.upvotes.length}</b> upvotes &nbsp; | &nbsp; Prefix: <b>{this.state.prefix}</b>
                  </span>
               </div>
            </div>
            
            <br />
            <br />
            <br />
            <br />
            {/* pls dont hate me. there wasn't another way */}

            <div className="box" id="longdesc-frame">
               <ReactIframeResizer iframeResizerOptions={{ checkOrigin: false, title: this.state.botname }}>
                  <span style={{color: '#ffffff', fontFamily: "'Work Sans', sans-serif", display:"block", fontWeight: 500, overflow: "hidden", width: "90%"}} dangerouslySetInnerHTML={{__html: `<style>code{color: pink} table, a{color: white;  border-collapse: collapse;} a{color: lightblue; transition: 200ms ease} a:hover{color: #5865F2} th{text-align:left;} td, th{padding: 8px 5px; border: 3px solid #363c41; margin-top: -1px;} </style><base target="_blank" /> ${this.state.finalLongdesc}`}} />
               </ReactIframeResizer>
            </div>
         </>
      )
   }
}

function convertFlags(flags) {
   if (typeof flags == "string") flags = flags.split(" ")
   
   const finalFlags = []
   flags?.forEach(flag => {
      if (badgeURLS[flag]) {
      const badge = new Badge(flag, 20)
      finalFlags.push(badge.getHTML())
      }
   })

   return finalFlags
}

function getCookie(cname){
   var name = cname + "=";
   var decodedCookie = decodeURIComponent(document.cookie);
   var ca = decodedCookie.split(';');
   for(var i = 0; i <ca.length; i++) {
     var c = ca[i];
     while (c.charAt(0) === ' ') {
       c = c.substring(1);
     }
     if (c.indexOf(name) === 0) {
       return c.substring(name.length, c.length);
     }
   }
   return "";
}

export default Botpages;