import React from 'react'
import './404.css'

class Error404 extends React.Component {
    constructor(){
        super()
        this.i = 0;
        this.handleRickRoll = this.handleRickRoll.bind(this)
    }

    handleRickRoll(){
        this.i++
        if(this.i === 5){
            window.location.href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
        }
    }
    
    render() {
        return (
            <div id="p404">
               <div id="CONTENT">
                    <center>
                        <div id="BOX">
                            <strong>404</strong>
                            <section>Looks like you got lost... Not good<section onClick={() => this.handleRickRoll()} style={{display: "contents", cursor: "pointer"}}>.</section></section>
                            <p onClick={() => window.location.href = `/`}>
                                ⮐ Back to Home
                            </p>
                        </div>
                    </center>
                </div>
            </div>
        )
    }
}

export default Error404