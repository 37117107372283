import React from 'react'
import fetch from "cross-fetch"
import config from "../../../assets/config.json"
import './newticket.css'

class NewTicket extends React.Component{

    async submitTicket() {
        await fetch(`${config.hosts.INTERNAL_API}/ticket/create`, {
            method: "POST",
            headers: {
                description: document.getElementById("ticket-description").value
            },
            credentials: 'include'
        }).then(res => res.json()).then(async ans => {
            window.location.href = ans.redirect
        })
    }

    render(){
        return(
            <>
                <div class="main-div-ticket">
                    <div id="cert_center">
                        <div id="cert">
                            <div id="head">
                                
                                <p>
                                    <b class="ticket-header">Create new ticket</b> <br/>
                                    <span style={{color: "gray"}}>
                                        <b>You got stuck? Our nice support team can help you out.</b>
                                    </span>
                                </p>
                            </div>

                            <textarea id="ticket-description" placeholder="Tell us your problem as detailed as possible so we can help you best"></textarea>

                            <p id="error-cert">
                                By submitting an ticket you accept our ticket rules
                            </p>
                            
                            <button onClick={() => this.submitTicket()}>
                                Create ticket
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default NewTicket;