import React from 'react'
import config from '../../../assets/config.json'
import PopOut from '../../utility/popup'

class Queue extends React.Component{

    constructor(params){
        super(params)
        this.state = {data: ""}

        this.sayHi = this.sayHi.bind(this)
    }

    async componentDidMount(){
        await fetch(`${config.hosts.INTERNAL_API}/UserID`,{
            method: "GET",
              headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                credentials: 'include',
          })
          .then(res => res.json())
          .then(data => {
            console.log(data)
            if(data.isMod !== true) return window.location.href = "/"
            this.setState({id: data.id})
        })
        
        fetch(`${config.hosts.INTERNAL_API}/api/queue`, {
            method :"GET", 
            headers:{
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        }).then(res => res.json()).then((data) => { 
            console.log(data)
            this.setState({data: data})
        })
    }

    toggleCheck(checkbox){
        var checkboxes = document.getElementsByName('check')
        checkboxes.forEach((item) => {
            if (item !== checkbox.target){
                item.checked = false
            }
        })
    }

    toggleReportPopup(type, botid, state) {
        this.setState({type: type})
        if(type === "Approve"){
            this.setState({popup: 
                <>
                    <h3>Approve</h3>
                    <p>
                        Anything you would like to add? If not, click approve
                    </p>
                    <textarea id="reportDescription" placeholder="Bla bla bla write something in here"></textarea>
                    <button onClick={() => this.submitReport(botid, document.getElementById("reportDescription").value)}>
                        {type}
                    </button>
                </>
            })
        }else if(type === "Deny"){
            this.setState({popup: 
                <>
                    <h3>Deny</h3>
                        <p>
                            Reason:
                        </p>
            
                        <input type="checkbox" name="check" id="offline" onClick={(e)=>this.toggleCheck(e)} /><label>Bot was offline</label><br />
                        <input type="checkbox" name="check" id="help" onClick={(e)=>this.toggleCheck(e)}/><label>Help command not working</label><br />
                        <input type="checkbox" name="check" id="went-offline" onClick={(e)=>this.toggleCheck(e)}/><label>Bot went offline durring testing</label><br />
                        <input type="checkbox" name="check" id="spam" onClick={(e)=>this.toggleCheck(e)}/><label>Longdesc filled with spam</label><br />
                        <p>Others:</p>
                        <textarea id="reportDescription" placeholder="Bla bla bla write something in here"></textarea>
                    <button id="deny" onClick={() =>{ 
                        var inputs = [document.getElementById("offline").checked, document.getElementById("help").checked, document.getElementById("went-offline").checked, document.getElementById("spam").checked, document.getElementById("reportDescription").value]
                        for(var i = 0; i < inputs.length; i++){
                            if(inputs[i] === true)
                            {
                                return this.submitReport(botid, Number(i+1))
                            }else if(i === (inputs.length -1) && inputs[i] !== undefined){
                                return this.submitReport(botid, inputs[i])
                            }
                        }
                        }}>
                        {type}
                    </button>
                </>
            })
        }else{ return }

        // if (state === undefined) {
        //     document.getElementById("report-popup-bg").style.display = "block"
        //     document.getElementById("report-popup").style.display = "block"
            
        //     setTimeout(()=>{
        //         document.getElementById("report-popup-bg").style.opacity = 1
        //         document.getElementById("report-popup").style.opacity = 1
        //     },100)
        // } else {
        //     document.getElementById("report-popup-bg").style.opacity = 0
        //     document.getElementById("report-popup").style.opacity = 0
        //     setTimeout(()=>{
        //         document.getElementById("report-popup-bg").style.display = "none"
        //         document.getElementById("report-popup").style.display = "none"
        //     },600)
        // }
    }

    handleOnClick = (event) => {
        event.target.innerHTML = "Loading"
    }

    claimbot(id){
        fetch(`${config.hosts.INTERNAL_API}/api/queue/claim?bot=${id}`, {
            credentials: 'include'
        }).then(res => res.json()).then((data) => { 
            if(data.length === 0) data = undefined
            this.setState({needtest: data})
        })

        fetch(`${config.hosts.INTERNAL_API}/api/queue`, {
            method :"GET", 
            headers:{
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        }).then(res => res.json()).then((data) => {
            this.setState({data: data})
        })
    }

    getCookie(cname){
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }

    sayHi(params) {
        console.log(params)
    }

    async submitReport(botid, reason) {
        await fetch(`${config.hosts.INTERNAL_API}/mod/${this.state.type}/${botid}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                reason: reason,
                modid: this.state.id
            }),
            credentials: 'include'
        }).then(res => res.json()).then(async (res) => {
            if(res.status === 200){
                this.toggleReportPopup(this.state.type, 0, "close")
                setTimeout(()=>{
                    window.location.reload();
                },500)
            }
        })
    }

    render(){
        return(
            <>
                 <PopOut on={this.state.popup ? true : false} content={this.state.popup} />
                <strong style={{fontSize: "30px"}}>Queue</strong><br />
                {this.state.data ? this.state.data.length === 0 ? <i style={{color: "gray", marginTop: "30px", marginBottom: "10px"}}>Empty. Good job!</i> : this.state.data?.map(e => <>
                    <div id="test-container">
                        <img src={e.icon} alt="bot_icon"/>
                        <strong style={{marginLeft: "10px"}}>{e.name}</strong>
                        <div id="desc">{e.desc}</div>
                        <div id="owner" style={{marginLeft: "110px", color: "grey"}}>by {e.developer}</div>
                        <div id="others-container">
                            <div id="element">
                                <strong>Notes:</strong>
                                <br />
                                {e.notes ? e.notes : "None"}
                            </div>
                            <div id="element">
                                {e.denyedbecause ? <>
                                    <strong>Previously denied:</strong>
                                    <br />
                                    {e.denyedbecause}
                                </>: ""}
                            </div>
                        </div>
                        {(e.selfClaimed) ? <div id="buttons"><button target="_blank" onClick={()=>window.open(e.invite, '_blank').focus()}>Invite</button><button target="_blank" onClick={()=>window.open(`https://discord-botlist.eu/bots/${e.id}`, '_blank').focus()}>Botpage</button><button class="approve" onClick={() => this.toggleReportPopup("Approve", e.id)}>Approve</button><button class="deny" onClick={() => this.toggleReportPopup("Deny", e.id)}>Deny</button></div> : ((e.claimed !== true) ? <div id="buttons"><button target="_blank" onClick={ (event) => {this.claimbot(e.id); this.handleOnClick(event)} }>Claim</button></div>: <div id="buttons"><button target="_blank" disabled>Claimed</button></div>)}
                    </div>
                </>): "loading"}
            </>
        )
    }
}

export default Queue