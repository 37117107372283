import React from 'react'
import Card from '../utility/card'
import { ReactComponent as Wave } from '../../assets/wave.svg';
import { ReactComponent as Glas } from '../../assets/glas.svg';
import { ReactComponent as Fun } from '../../assets/fun.svg';
import { ReactComponent as Moderation } from '../../assets/moderation.svg';
import { ReactComponent as Dashboard } from '../../assets/dashboard.svg';
import { ReactComponent as Advertising } from '../../assets/advertising.svg';
import { ReactComponent as Leveling } from '../../assets/leveling.svg';
import { ReactComponent as Logging } from '../../assets/logging.svg';
import { ReactComponent as New } from '../../assets/new.svg'
import { ReactComponent as Upvotes } from '../../assets/upvote.svg';
import { ReactComponent as Partner } from '../../assets/partner.svg';
import '../../cards.css'
import config from '../../assets/config.json'
import {Helmet} from "react-helmet";

class SearchSuggestion extends React.Component {
    render() {
        return (
            <div onClick={() => window.location.href = `/bots/${this.props.id}`}>
                <img src={this.props.icon} alt="icon"/>
                <span>
                    {this.props.name}
                </span>
            </div>
        )
    }
}

class Homepage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        fetch(`${config.hosts.INTERNAL_API}/homepage/search`, { 
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',

        }).then(res => res.json()).then(data => this.setState({
            search: data
        }))

        // fetch(`https://cdn.discord-botlist.eu/other/ad.json`, { 
        //     method: "GET",
        //     headers: {
        //         Accept: 'application/json',
        //         'Content-Type': 'application/json',
        //     },
        //     credentials: 'include',

        // }).then(res => res.json()).then(data => 
        this.setState({adlink: "https://discord-botlist.eu/get-ad", adimage: "https://cdn.discord-botlist.eu/pictures/ad.png"})

        fetch(`${config.hosts.INTERNAL_API}/homepage`, { 
            method: "GET",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',

        }).then(res => res.json()).then(data => this.setState(data))
    }

    render() {
        return (<>
        <Helmet>
            <title>Home | discord-botlist.eu</title>
            <meta name="description" content="discord-botlist.eu offers a wide variety of bots for your personal needs. Find your bot today!" />
            <meta name="robots" content="index, follow" />
        </Helmet>
            <div>
                <div id="splash">
                    <div id="left">
                    <h1 id="title">
                        discord-botlist.eu
                    </h1>
                    <p id="sub-title">
                        discord-botlist.eu offers a wide variety of bots for your personal needs. Find your bot today!
                    </p>
                    <form id="search-parent" onSubmit={(e) => {
                        window.location.href = `/search?q=${document.getElementById("search-query").value}`
                        e.preventDefault()
                    }}>
                        <Glas /> <input autoComplete="off"  placeholder="Search for something..." type="search" id="search-query" onChange={(i) => {
                            if (i.target.value === "") return this.setState({
                                matches: []
                            })

                            const matches = this.state.search?.filter(e => e.name.toLowerCase().includes(i.target.value.toLowerCase())).slice(0, 5)
                            this.setState({
                                matches: matches
                            })
                        }} />
                    </form>
                    <div id="search-sgt"> {this.state.matches?.map(e => <SearchSuggestion name={e.name} id={e.id} icon={e.icon} />)} </div>
                    <div id="tags-parent">
                        <div className="tag1" onClick={() => window.location.href = "/search?q=fun"}>
                            <Fun /> <span>Fun</span>
                        </div>
                        <div className="tag1" onClick={() => window.location.href = "/search?q=moderation"}>
                            <Moderation /> <span>Moderation</span>
                        </div>
                        <div className="tag1" onClick={() => window.location.href = "/search?q=dashboard"}>
                            <Dashboard /> <span>Dashboard</span>
                        </div>
                        <div className="tag1" onClick={() => window.location.href = "/search?q=advertisment"}>
                            <Advertising /> <span>Advertisment</span>
                        </div>
                        <div className="tag1" onClick={() => window.location.href = "/search?q=leveling"}>
                            <Leveling /> <span>Leveling</span>
                        </div>
                        <div className="tag1" onClick={() => window.location.href = "/search?q=logging"}>
                            <Logging /> <span>Logging</span>
                        </div>
                    </div>
                </div>
                <div id="right">
                </div>
                </div>
                < Wave />
                {/* <div id="ad-label" style={{cursor: "pointer"}} onClick={()=>window.location.href= this.state.adlink}>
                    <div id="ad-content">
                    <img src={this.state.adimage} />
                <section id="ad-label-ad">ad</section>
                </div>
                </div> */}
                
                <div id="lists">
                    <div id="title-container">
                        <p id="headline">
                            <Upvotes /> Most Upvotes
                        </p>
                        <section id="headline-desc">
                            The most upvotes this month
                        </section>
                    </div>
                    <div className="card-group">
                    {this.state.trending ? this.state.trending?.map(e =>  (e.denied === true) ? "" : <Card borderColor={e.border_color} id={e.id} vanity={e.vanity} banner={e.banner} flags={e.flags} icon={e.icon} name={e.name} desc={e.desc} serverCount={e.server} upvotes={e.upvotes} />) : <>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                        </>}
                    </div> <br/>
                    <div id="title-container">
                        <p id="headline">
                            <New /> Newest
                        </p>
                        <section id="headline-desc">
                            Newly added bots
                        </section>
                    </div>
                    <div id="LIST_NEWEST" className="card-group">
                        {this.state.newest ? this.state.newest?.map(e =>  (e.denied === true) ? "" : <Card borderColor={e.border_color} id={e.id} vanity={e.vanity} banner={e.banner} flags={e.flags} icon={e.icon} name={e.name} desc={e.desc} serverCount={e.server} upvotes={e.upvotes} />) : <>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                        </>}
                    </div> <br/>
                    <div id="title-container">
                        <p id="headline">
                            <svg>
                                <image alt="medal" href="https://cdn.discord-botlist.eu/pictures/certified_bot.svg" width="30" height="30"/>
                            </svg> Certified
                        </p>
                        <section id="headline-desc">
                            Random certified Bots
                        </section>
                    </div>
                    <div className="card-group">
                        {this.state.rdmCertified ? this.state.rdmCertified?.map(e =>  (e.denied === true) ? "" : <Card borderColor={e.border_color} id={e.id} vanity={e.vanity} banner={e.banner} flags={e.flags} icon={e.icon} name={e.name} desc={e.desc} serverCount={e.server} upvotes={e.upvotes} />) : <>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                            <Card dummy="true"/>
                        </>}
                    </div> <br/>
                    <div id="title-container">
                        <p id="headline">
                        <Partner /> Our Partners
                        </p>
                        <section id="headline-desc">
                            Our Partners
                        </section>
                    </div>
                    <div className="card-group">
                        <Card id={0} vanity={0} click={"https://zap-hosting.com/en"} flags={"none"} banner={"https://cdn.discord-botlist.eu/pictures/zap_banner.jfif"} icon={"https://cdn.discord-botlist.eu/pictures/zap.png"} name={"Zap-Hosting"} desc={"Zap Hosting is sponsoring this project with on of their V-Servers. Thank you <3"} serverCount={2} upvotes={"∞"} />
                        <Card id={0} vanity={0} click={"https://www.caards.me"} flags={"none"} banner={"https://cdn.discord-botlist.eu/pictures/caards_banenr.png"} icon={"https://media.discordapp.net/attachments/868023324402397235/869286242754383902/logo.png?width=905&height=905"} name={"Caards"} desc={"Caards.me is a website where you can create your \"caard\"! You can link your social network accounts, find friends, and much more!"} serverCount={"∞"} upvotes={"∞"}/>
                        <Card empty="true"/>
                        <Card empty="true"/>
                        <Card empty="true"/>
                        <Card empty="true"/>
                        <Card empty="true"/>
                        <Card empty="true"/>
                        <Card empty="true"/>
                        <Card empty="true"/>
                    </div> <br/>
                </div>

            </div>
        </>)
    }
}

export default Homepage