import React from 'react'
import ReactDOM from 'react-dom'
import './main.css'
import './cards.css'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router, Route, Switch} from "react-router-dom"
import config from './assets/config.json'

import Footer from './pages/utility/footer'
import Homepage from './pages/home/homepage'
import Staffs from './pages/staff/staffs'
import Botpage from './pages/botpage/botpage'
import Submit from './pages/submit/submit'
import Header from './pages/header/header'
import Allbots from './pages/allbots/allbots'
import Vote from './pages/vote/vote'
import Mod from './pages/apply/mod/mod'
import Search from './pages/search/search'
import Error404 from './pages/404/404'
import Manage from './pages/user/user'
import Profile from './pages/profiles/profiles'
import Callback from './pages/utility/callback'
import Logout from './pages/utility/logout'
import Gifts from './pages/gifts/gifts'
import TicketRedirect from './pages/tickets/redirect/ticketredirect'
import NewTicket from './pages/tickets/new/newticket'
import TicketViewer from './pages/tickets/view/tickerviewer'
import Edit from './pages/edit/edit'
import Report from './pages/report/report'
import Cert from './pages/cert/cert'
import Delete from './pages/delete/delete'
import Terms from './pages/terms/terms'
import InfoCert from './pages/info/cert'
import PartnerInfo from './pages/info/partner'
import requestad from './pages/info/request-ad'
import privacy from './pages/pp/pp'
import imprint from './pages/info/imprint'

class Login extends React.Component {
  componentDidMount() {
    console.log(this.props.url)
    window.location.href = config.hosts.LOGIN
  }

  render() {
    return <></>
  }
}

if (!["/callback", "/login", "/logout"].includes(window.location.pathname)) window.localStorage.setItem("lastVisited", window.location.href)

const utm = new URLSearchParams(window.location.search).get("utm_source")
if (utm) fetch(`${config.hosts.INTERNAL_API}/utm`, {
  method: "POST",
  headers: {
    "Content-Type": "application/json"
  },
  body: JSON.stringify({
    utm: utm
  })
}).catch(() => { })

ReactDOM.render(
  <Router>
    <Header />
      <Switch>
        <Route exact path="/" component={Homepage}/>
        <Route exact path="/tickets/" component={TicketRedirect}/>
        <Route exact path="/tickets/new" component={NewTicket}/>
        <Route exact path="/tickets/view/:id" component={TicketViewer}/>
        <Route exact path="/staffs" component={Staffs}/>
        <Route exact path="/bots/:id" component={Botpage}/>
        <Route exact path="/submit" component={Submit} />
        <Route exact path="/all" component={Allbots} />
        <Route exact path="/vote/:id" component={Vote} />
        <Route exact path="/apply/:apply" component={Mod} />
        <Route exact path="/search" component={Search} />
        <Route exact path="/manage/me" component={Manage} />
        <Route exact path="/users/:id" component={Profile} />
        <Route exact path="/callback" component={Callback} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/gift/:gift" component={Gifts} />
        <Route exact path="/edit/:botid" component={Edit} />
        <Route exact path="/report/:botid" component={Report} />
        <Route exact path="/certify/:botid" component={Cert} />
        <Route exact path="/delete/:botid" component={Delete} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/tos" component={Terms} />
        <Route exact path="/info/certify" component={InfoCert} />
        <Route exact path="/info/cert" component={InfoCert} />
        <Route exact path="/info/certification" component={InfoCert} />
        <Route exact path="/partner" component={PartnerInfo} />
        <Route exact path="/get-ad" component={requestad} />
        <Route exact path="/privacy" component={privacy} />
        <Route exact path="/imprint" component={imprint} />
        <Error404 />
      </Switch>
    <Footer />
  </Router>,
  document.getElementById('root')
)

reportWebVitals(console.log)