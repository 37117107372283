import React from 'react'
import './mod.css'

class Mod extends React.Component{
    constructor(params){
        super(params)
    }

    render(){
        return(
            <>
      
                {/* <div id="container-apply">
                    <div id="title">Mod application</div>
                    <div id="sub">You always wanted to be a bot tester? Lorem Ipsum bkllblajsdbasjdashdkhsjkd</div>
                        <div id="conatiner-apply-input">
                            <section class="input-desc">How old are you?</section>
                            <input type="number" name="age" class="input" placeholder="18"></input>
                            <section class="input-desc">Have you ever been moderator on a server or other website?</section>
                            <input type="checkbox" name="mod-on-other-site-yes" class="input"></input><label>Yes</label><br></br>
                            <input type="checkbox" name="mod-on-other-site-no" class="input"></input><label>NO</label>
                            <section class="input-desc">Your online time in h</section>
                            <input type="number" name="online-time" class="input" placeholder="7"></input>
                            <section class="input-desc">Your timezone</section>
                            <input name="timezone" class="input" placeholder="UTC+1"></input>
                            <section class="input-desc">What languages do you speak? (English is required)</section>
                            <input name="langs" class="input" placeholder="English"></input>
                            <section class="input-desc">What are your strengths?</section>
                            <input name="strenghts" class="input" placeholder="Im cool"></input>
                            <section class="input-desc">What are your weaknesses (Just writing "none" will not work buddy ;D)</section>
                            <input name="weaknesses" class="input" placeholder="I like cookies"></input>
                            <section class="input-desc">Describe yourself in at least 100 words</section>
                            <input name="desc-long" class="input" placeholder="Cool Person..."></input>
                            <section class="input-desc">Are you sharing your accounts with friends?</section>
                            <input type="checkbox" name="share-yes" class="input"></input><label>Yes</label><br></br>
                            <input type="checkbox" name="share-no" class="input"></input><label>NO</label>
                            <button type="submit" class="input" style={{height: "40px", width: "100%", cursor:"pointer"}}>Submit</button>
                        </div>
                </div> */}
                Will be done soon lol ignore this
                
                Okay
            </>
        )
    }
}

export default Mod;