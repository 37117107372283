import React from 'react'

class reqad extends React.Component{
    render(){
        return(
        <>
            <div id="info-window">
                <div id="head">
                    <p id="title">
                        <u>Get your ad on our website</u>
                    </p>
                </div>

                <div id="content">
                <section id="introduction">
                        <p>
                            How to get my ad on the site?
                        </p>
                        In principle, we do not offer any contracts. All agreements are based on a fair to one another. Prices etc are discussed privately. If you want to get in touch, click here:
                        <br />
                        <button id="req" onClick={() => window.location.href="https://discord.com/users/462340067864870923"}>Contact us</button>
                    </section>
                </div>

            </div> 
        </>
        )
    }
}

export default reqad;