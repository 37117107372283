import React from 'react'
import fetch from 'cross-fetch'
import config from '../../assets/config.json'
import Error404 from '../404/404'
import './profiles.css'
import '../../cards.css'
import Card from '../utility/card'
import { ReactComponent as Loading } from '../../assets/loading.svg'
import { ReactComponent as Link } from '../../assets/link.svg'

const badges = {
    "ADMIN": "https://cdn.discord-botlist.eu/pictures/admin.svg",
    "MOD": "https://cdn.discord-botlist.eu/pictures/mod.svg",
    "PREMIUM": "https://cdn.discord-botlist.eu/pictures/premium.svg",
    "CERTIFIED_DEV": "https://cdn.discord-botlist.eu/pictures/certified_dev.svg"
}

class UserLink extends React.Component {
    render() {
        if (this.props.discord) return (
            <div className="pf-link" onClick={() => window.location.href = this.props.discord}>
                <Link /> Discord
            </div>
        )

        if (this.props.website) return (
            <div className="pf-link" onClick={() => window.location.href = this.props.website}>
                <Link /> Website
            </div>
        )

        return <></>
    }
}

class Profile extends React.Component {
    constructor() {
        super()
        this.state = { }
    }

    async componentDidMount() {
        const data = await fetch(`${config.hosts.INTERNAL_API}/profile/${this.props.match.params.id}`).then(res => res.json()).catch(() => this.setState({error: 404}) && undefined)
        if (!data) return
        if ([404].includes(data.status)) return this.setState({error: data.status})
        this.setState(data)
    }

    getBadges(flags) {
        const res = []
  
        for (let i = 0; i < flags.length; i++) {
           const flag = flags[i]
           if (badges[flag]) res.push(`<img class="dev-badge" src=${badges[flag]} style="margin-left: 5px; width: 20px;" />`)
        }
  
        return res.join("")
    }

    getTime(ms) {
        const years = Math.floor(ms / 31536000000)
        const months = Math.round(ms / 2628000000 - years * 12)
        return `${years} year${years >= 2 ? "s" : ""} and ${months} month${months >= 2 ? "s" : ""}`
    }

    render() {
        if (this.state.error === 404) return <Error404 />
        if (!this.state.id) return (
            <div id="CALLBACK_LOADING">
                <Loading />
            </div>
        )

        const statusColors = {
            "dnd": "#ED4245",
            "online": "#57F287",
            "idle": "#FEE75C",
            "offline": "#747F8D"
        }

        if (window.innerWidth <= 1000) {
            return (
                <>
                    <div style={{marginTop: "70px"}}>
                        <div id="user_card">
                            <div id="info_general">
                                <img src={this.state.avatar} style={{borderColor: statusColors[this.state.status]}} id="avatar" alt="avatar"/>
                                <p id="username">
                                    {this.state.username}<span style={{color: "gray", fontSize: "13px"}}>#{this.state.tag}</span> <span id="badges" dangerouslySetInnerHTML={{__html: this.getBadges(this.state.flags)}}></span> <br/>
                                    <span id="bio">
                                        {this.state.database.bio}
                                    </span>
                                    <div id="pf-links">
                                        {this.state.database.discord ? <UserLink discord={this.state.database.discord} /> : <></>}
                                        {this.state.database.website ? <UserLink website={this.state.database.website} /> : <></>}
                                    </div>
                                </p>
                            </div>
                        </div>
                        {this.state.bots?.length >= 1 ? <div className="card-group-profile">
                            {this.state.bots?.map(e => <Card borderColor={e.border_color} id={e.id} vanity={e.vanity} banner={e.banner} flags={e.flags} icon={e.icon} name={e.name} desc={e.desc} serverCount={e.server} upvotes={e.upvotes} />)}
                        </div> : <center>
                            <br />
                            <br />
                            <i>This user has no bots</i>
                        </center>}
                    </div>
                </>
            )
        }

        return (
            <>
                <div style={{marginTop: "70px"}}>
                    <div id="user_card">
                        <div id="pf-links">
                            {this.state.database.discord ? <UserLink discord={this.state.database.discord} /> : <></>}
                            {this.state.database.website ? <UserLink website={this.state.database.website} /> : <></>}
                        </div>

                        <div id="info_general">
                            <img src={this.state.avatar} style={{borderColor: statusColors[this.state.status]}} id="avatar" alt="avatar"/>
                            <span id="username">
                                {this.state.username}<span style={{color: "gray", fontSize: "13px"}}>#{this.state.tag}</span> <span id="badges" dangerouslySetInnerHTML={{__html: this.getBadges(this.state.flags)}}></span> <br/>
                                <p id="bio">
                                    {this.state.database.bio}
                                </p>
                            </span>
                        </div>
                    </div>
                    {this.state.bots?.length >= 1 ? <div className="card-group-profile">
                        {this.state.bots?.map(e => <Card borderColor={e.border_color} id={e.id} vanity={e.vanity} banner={e.banner} flags={e.flags} icon={e.icon} name={e.name} desc={e.desc} serverCount={e.server} upvotes={e.upvotes} />)}
                    </div> : <center>
                        <br />
                        <br />
                        <i>This user has no bots</i>
                    </center>}
                </div>
            </>
        )
    }
}

export default Profile