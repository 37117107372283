import React from 'react'
import './allbots.css'
import Card from '../utility/card'
import config from '../../assets/config.json'

class Allbots extends React.Component {
    constructor(params){
        super(params)
        this.state = {}
        this.click = this.click.bind(this)
    }

    componentDidMount(){
        var cur_url = window.location.href
        var page = cur_url.split("=")[1]
        if(!page) page = 1;
        fetch(`${config.hosts.INTERNAL_API}/api/all?page=${page}`, {
            credentials: 'include'
        })
        .then(res => res.json())
        .then((res) =>{
        this.setState(res)
        this.setState({page: Number(page)})
        })
    }

    click(number, plusminus){
        if(plusminus === "+"){
        window.location.href = `/all?page=${Number(this.state.page + number)}`
        }else{
            window.location.href = `/all?page=${this.state.page - number}` 
        }
    }

    render(){
        return(
            <>
                <div id="headline-all">
                    All Bots
                    <section id="sub-headline">{this.state.count ? this.state.count+" Bots / Page "+ this.state.page : "Loading..."}</section>
                </div>
                <div className="card-group-search">
                    {this.state.all ? this.state.all?.map(e => <Card borderColor={e.border_color} id={e.id} flags={e.flags} vanity={e.vanity} banner={e.banner} icon={e.icon} name={e.name} desc={e.desc} serverCount={e.server} upvotes={e.upvotes} />): <>
                        <Card dummy="true" /> <Card dummy="true" /> <Card dummy="true" /> <Card dummy="true" /> <Card dummy="true" /> <Card dummy="true" /> <Card dummy="true" />  <Card dummy="true" /> <Card dummy="true" /> <Card dummy="true" /> <Card dummy="true" /> <Card dummy="true" /> <Card dummy="true" />
                    </>}
                </div>
                
                {this.state.page ?
                <div id="page-thing">
                    <div id="left-page-thing" onClick={()=> this.click(1,"-")}>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 492 492" xmlSpace="preserve">
                            <path d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12
                            C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084
                            c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864
                            l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z"/>
                        </svg>
                    </div>
                    <section id="before-page-thing">
                        <a href={'/all?page='+Number(this.state.page - 4)}>{this.state.page - 4 < 1 ? "": this.state.page - 4}</a>
                        <a href={'/all?page='+Number(this.state.page - 3)}>{this.state.page - 3 < 1 ? "": this.state.page - 3}</a>
                        <a href={'/all?page='+Number(this.state.page - 2)}>{this.state.page - 2 < 1 ? "": this.state.page - 2}</a>
                        <a href={'/all?page='+Number(this.state.page - 1)}>{this.state.page - 1 < 1 ? "": this.state.page - 1}</a>
                    </section>
                    <section id="current-page-thing">{this.state.page}</section>
                    <section id="after-page-thing">
                        <a href={'/all?page='+Number(this.state.page + 1)}>{Number(this.state.page) + 1}</a>
                        <a href={'/all?page='+Number(this.state.page + 2)}>{Number(this.state.page) + 2}</a>
                        <a href={'/all?page='+Number(this.state.page + 3)}>{Number(this.state.page) + 3}</a>
                        <a href={'/all?page='+Number(this.state.page + 4)}>{Number(this.state.page) + 4}</a>
                    </section>
                    <div id="right-page-thing" onClick={()=> this.click(1,"+")}>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 492 492" style={{transform: "rotate(180deg)"}} xmlSpace="preserve">
                            <path d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12
                            C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084
                            c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864
                            l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z"/>
                       </svg>
                    </div>
                </div> : ""}
            </>
        )
    }
}

export default Allbots