import React from 'react'
import config from '../../../assets/config.json'
import Card from '../../utility/card'

class MyBots extends React.Component{
  constructor(){
    super()
    this.state = {userBots: "", id: ""}
  }

  async componentDidMount(){
  await fetch(`${config.hosts.INTERNAL_API}/UserID`,{
    method: "GET",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
    .then(res => res.json())
    .then(data => {
      console.log(data)
      this.setState({id: data.id})
    })

    fetch(`${config.hosts.INTERNAL_API}/api/botUser?id=${this.state.id}`, {
      method:"GET"
    }).then(res => res.json()).then(data => {
      console.log(data)
      this.setState({userBots: data})
    })
  }

  getCookie(cname){
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  render(){
    return(
      <>
      <strong style={{fontSize: "30px"}}>Manage your Bots</strong><br />
      <div id="input-desc" style={{marginTop: "30px", marginBottom: "-10px"}}>Your Bots</div>
      <div class="card-container" style={{marginLeft: "-10px", display: "table-cell"}}>
        {this.state.userBots ? this.state.userBots?.map(e => (e.denied === true) ? "" : <Card id={e.id} key={e.id} vanity={e.vanity} banner={e.banner} flags={e.flags} icon={e.icon} name={e.name} desc={e.desc} edit="true" serverCount={e.server} upvotes={e.upvotes} />) : <Card dummy="true" />}
      </div>
      </>
    )
  }
}

export default MyBots;